const icons = {
	table: "fal fa-table",
	truck: "fas fa-truck",
	truckLoading: "fas fa-truck-loading",
	list: "fas fa-list",
	tasks: "fas fa-tasks",
	duck: "fas fa-duck",
	calendar: "far fa-calendar",
};

export const lineHaul = {
	LineHaulMovementsLink: {
		appDomain: "beta",
		uri: `/line-haul-movement/all`,
		uriRegex: [/\/line-haul-movement\/(.*)/g],
		label: {
			old: "Movements",
			new: "Movements",
		},
		icon: {
			old: icons.table,
			new: icons.table,
		},
		showBetaBadge: {
			old: false,
			new: false,
		},
	},
	LineHaulMovementsAllocatorLink: {
		appDomain: "beta",
		uri: `/line-haul-movement/allocator`,
		uriRegex: [/\/line-haul-movement\/allocator/g],
		label: {
			old: "Line Haul Allocator",
			new: "Line Haul Allocator",
		},
		icon: {
			old: icons.truck,
			new: icons.truck,
		},
		showBetaBadge: {
			old: false,
			new: false,
		},
	},
	LineHaulMovementsBuilderLink: {
		appDomain: "beta",
		uri: `/line-haul-movement/builder`,
		uriRegex: [/\/line-haul-movement\/builder/g],
		label: {
			old: "Line Haul Builder",
			new: "Line Haul Builder",
		},
		icon: {
			old: icons.truckLoading,
			new: icons.truckLoading,
		},
		showBetaBadge: {
			old: false,
			new: false,
		},
	},
	LineHaulJobsLink: {
		appDomain: "beta",
		uri: `/line-haul-job/all`,
		uriRegex: [/\/line-haul-job\/(.*)/g],
		label: {
			old: "Jobs",
			new: "Jobs",
		},
		icon: {
			old: icons.list,
			new: icons.list,
		},
		showBetaBadge: {
			old: false,
			new: false,
		},
	},
	LineHaulJobAcceptanceLink: {
		appDomain: "beta",
		uri: `/line-haul-job/acceptance`,
		uriRegex: [/\/line-haul-job\/acceptance/g],
		label: {
			old: "Job Acceptance",
			new: "Job Acceptance",
		},
		icon: {
			old: icons.tasks,
			new: icons.tasks,
		},
		showBetaBadge: {
			old: false,
			new: false,
		},
	},
	LineHaulScoreboardLink: {
		appDomain: "beta",
		uri: `/line-haul-job/scoreboard`,
		uriRegex: [/\/line-haul-job\/scoreboard/g],
		label: {
			old: "Job Scoreboard",
			new: "Job Scoreboard",
		},
		icon: {
			old: icons.duck,
			new: icons.duck,
		},
		showBetaBadge: {
			old: false,
			new: false,
		},
	},
	LineHaulTruckLink: {
		appDomain: "beta",
		uri: `/line-haul-truck/all`,
		uriRegex: [/\/line-haul-truck\/(.*)/g],
		label: {
			old: "Trucks",
			new: "Trucks",
		},
		icon: {
			old: icons.table,
			new: icons.table,
		},
		showBetaBadge: {
			old: false,
			new: false,
		},
	},
	LineHaulTrailerLink: {
		appDomain: "beta",
		uri: `/line-haul-trailer/all`,
		uriRegex: [/\/line-haul-trailer\/(.*)/g],
		label: {
			old: "Trailers",
			new: "Trailers",
		},
		icon: {
			old: icons.table,
			new: icons.table,
		},
		showBetaBadge: {
			old: false,
			new: false,
		},
	},
	LineHaulSubcontractorLink: {
		appDomain: "beta",
		uri: `/line-haul-subcontractor/all`,
		uriRegex: [/\/line-haul-subcontractor\/(.*)/g],
		label: {
			old: "Subcontractors",
			new: "Subcontractors",
		},
		icon: {
			old: icons.table,
			new: icons.table,
		},
		showBetaBadge: {
			old: false,
			new: false,
		},
	},
	LineHaulDollyLink: {
		appDomain: "beta",
		uri: `/line-haul-dolly/all`,
		uriRegex: [/\/line-haul-dolly\/(.*)/g],
		label: {
			old: "Dollys",
			new: "Dollys",
		},
		icon: {
			old: icons.table,
			new: icons.table,
		},
		showBetaBadge: {
			old: false,
			new: false,
		},
	},
	LineHaulLegLink: {
		appDomain: "beta",
		uri: `/line-haul-leg/all`,
		uriRegex: [/\/line-haul-leg\/(.*)/g],
		label: {
			old: "Legs",
			new: "Legs",
		},
		icon: {
			old: icons.table,
			new: icons.table,
		},
		showBetaBadge: {
			old: false,
			new: false,
		},
	},
	LineHaulLegScheduleLink: {
		appDomain: "beta",
		uri: `/line-haul-leg-schedule/all`,
		uriRegex: [/\/line-haul-leg-schedule\/(.*)/g],
		label: {
			old: "Leg Schedules",
			new: "Leg Schedules",
		},
		icon: {
			old: icons.calendar,
			new: icons.calendar,
		},
		showBetaBadge: {
			old: false,
			new: false,
		},
	},
	LineHaulScheduleLink: {
		appDomain: "beta",
		uri: `/line-haul-journey/all`,
		uriRegex: [/\/line-haul-journey\/(.*)/g],
		label: {
			old: "Line Haul Journey",
			new: "Line Haul Journey",
		},
		icon: {
			old: icons.calendar,
			new: icons.calendar,
		},
		showBetaBadge: {
			old: false,
			new: false,
		},
	},
};
