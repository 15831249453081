export const pickPack = {
    SummaryReportLink: {
        label: {
            old: "Summary Report",
            new: "Summary",
        },
        icon: {
            old: "fas fa-tachometer-alt",
            new: "fas fa-clipboard-list",
        },
    },
    ProductSummaryReportLink: {
        appDomain: "beta",
        uri: `/p/product-summary/all`,
        uriRegex: [/\/p\/product-summary\/(.*)/g],
        label: {
            old: "Product Summary Report",
            new: "Product Summary",
        },
        icon: {
            old: "fas fa-tachometer-alt",
            new: "fas fa-gauge-high",
        },
    },
    PickerPerformanceReportsLink: {
        appDomain: "beta",
        label: {
            old: "Picker Performance Reports",
            new: "Picker Performance",
        },
        icon: {
            old: "fas fa-tachometer-alt",
        },
    },
    FloorsLink: {
        appDomain: "beta",
        uri: `/p/floors/all`,
        uriRegex: [/\/p\/floors\/(.*)/g],
        label: {
            old: "Floors",
            new: "Product Floor sequences",
        },
        icon: {
            old: "fab fa-product-hunt",
            new: "fa-regular fa-arrow-up-small-big",
        },
    },
    ProductManagementLink: {
        appDomain: "beta",
        uri: `/p/products/all`,
        label: {
            old: "Product Management",
            new: "Packer Products",
        },
        icon: {
            old: "fab fa-product-hunt",
        },
        tooltip: {
            old: "'Product Management' are changing to 'Packer Products'",
            new: "'Packer Products' was titled 'Product Management'",
        },
    },
    OrderBoxItemStatusesLink: {
        appDomain: "beta",
        uri: `p/track-order-status/all`,
        label: {
            old: "Order/Box/Item statuses",
            new: "Order Status",
        },
        icon: {
            old: "fa fa-calendar-alt",
            new: "fa fa-calendar-days",
        },
    },
    WMSProductsLink: {
        appDomain: "beta",
        uri: `/wms/products/all`,
        uriRegex: [/\/wms\/products\/(.*)/g],
        label: {
            old: "WMS Products",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-warehouse-full",
        },
    },
    WMSLogsLink: {
        appDomain: "beta",
        uri: `/wms/logs/all`,
        uriRegex: [/\/wms\/logs\/(.*)/g],
        label: {
            old: "WMS Logs",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-warehouse-full",
        },
    },
    BetaWMSOrderItemsLink: {
        appDomain: "beta",
        uri: `/d/dotwms-order-items/all`,
        uriRegex: [/\/d\/dotwms-order-items\/(.*)/g],
        label: {
            old: "WMS Order Items",
            new: "WMS Order Items",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-warehouse-full",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    BetaWMSSyncedDeliveriesLink: {
        appDomain: "beta",
        uri: `/d/dotwms-synced-deliveries/all`,
        uriRegex: [/\/d\/dotwms-synced-deliveries\/(.*)/g],
        label: {
            old: "WMS Synced Deliveries",
            new: "WMS order sync",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-rotate",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    WarehouseInventoryLink: {
        appDomain: "beta",
        uri: `/wh/inventory/all`,
        uriRegex: [/\/wh\/inventory\/(.*)/g],
        label: {
            old: "Warehouse Inventory",
            new: "Inventory",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-warehouse-full",
        },
        tooltip: {
            old: "'Warehouse Inventory' are changing to 'Inventory'",
            new: "'Inventory' was titled 'Warehouse Inventory'",
        },
    },
    WarehousesLink: {
        appDomain: "beta",
        uri: `/wh/warehouses/all`,
        uriRegex: [/\/wh\/warehouses\/(.*)/g],
        label: {
            old: "WMS Warehouses",
            new: "WMS Warehouses",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-warehouse-full",
        },
    },
    BetaPurchaseOrdersLink: {
        appDomain: "beta",
        uri: `/po/purchase-orders/all`,
        uriRegex: [/\/po\/purchase-orders\/(.*)/g],
        label: {
            old: "Purchase Orders",
            new: "Purchase Orders",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-clipboard-list",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    BetaAdvancedShippingNoticesLink: {
        appDomain: "beta",
        uri: `/asn/advanced-shipping-notice/all`,
        uriRegex: [/\/asn\/advanced-shipping-notice\/(.*)/g],
        label: {
            old: "Advanced Shipping Notices",
            new: "Advanced Shipping Notices",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-cog",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
};
