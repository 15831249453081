export const deliveries = {
    DashboardLink: {
        appDomain: "beta",
        uri: `/d/overview`,
        uriRegex: [/\/d\/overview/g],
        label: {
            old: "Dashboard",
            new: "Overview",
        },
        icon: {
            old: "fas fa-tachometer-alt",
        },
    },
    RunDropErrorsLink: {
        appDomain: "beta",
        uri: `/run/drop-errors`,
        uriRegex: [/\/run\/drop-errors/g],
        label: {
            old: "Run Drop Errors",
            new: "Run Drop Errors",
        },
        icon: {
            old: "fas fa-exclamation-triangle",
            new: "fas fa-exclamation-triangle",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    BetaDeliveriesLink: {
        appDomain: "beta",
        uri: `/d/deliveries/all`,
        uriRegex: [/\/d\/deliveries\/(.*)/g],
        label: {
            old: "Deliveries",
            new: "Deliveries",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-box",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    BetaDeliveriesGeoIssueLink: {
        appDomain: "beta",
        uri: `/d/deliveries-geo-issues/all`,
        uriRegex: [/\/d\/deliveries-geo-issues\/(.*)/g],
        label: {
            old: "Deliveries Geo Issues",
            new: "Deliveries Geo Issues",
        },
        icon: {
            old: "fa-solid fa-location-dot-slash",
            new: "fa-solid fa-location-dot-slash",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    RunsLink: {
        appDomain: "beta",
        uri: `/run/runs/all`,
        uriRegex: [/\/run\/runs\/(.*)/g],
        label: {
            old: "Manage Runs",
            new: "Manage Runs",
        },
        icon: {
            old: "fas fa-person-running",
            new: "fas fa-person-running",
        },
        showBetaBadge: {
            old: false,
            new: false,
        },
    },
    RunRulesLink: {
        appDomain: "beta",
        uri: `/run/run-rules/all`,
        uriRegex: [/\/run\/run-rules\/(.*)/g],
        label: {
            old: "Manage Run Rules",
            new: "Manage Run Rules",
        },
        icon: {
            old: "fas fa-person-running",
            new: "fas fa-person-running",
        },
        showBetaBadge: {
            old: false,
            new: false,
        },
    },
    BetaBoxesLink: {
        appDomain: "beta",
        uri: `/d/boxes/all`,
        uriRegex: [/\/d\/boxes\/(.*)/g],
        label: {
            old: "Boxes",
            new: "Delivery Boxes",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-boxes",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    RunSummaryLink: {
        appDomain: "beta",

        uriRegex: [
            // /\/run\/r\/country\/1\/(.*)/g,
            /\/run\/r\/country\/(.*)\/(.*)\/rtw\/(.*)\/rg\/(.*)\/rz\/(.*)\/z\/(.*)\/c\/(.*)\/t\/(.*)/g,
            /\/run\/r\/country\/(.*)\/(.*)\/rtw\/(.*)\/rg\/(.*)\/rz\/(.*)\/z\/(.*)\/c\/(.*)\/run\/(.*)/g,
        ],
        label: {
            new: "Run Summary",
        },
        icon: {
            new: "fas fa-truck-bolt",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    LoadoutScreenLink: {
        appDomain: "beta",
        uri: `run/loadoutN`,
        uriRegex: [/\/run\/loadout/g, /\/run\/loadoutN/g],
        label: {
            new: "Loadout Dashboard",
        },
        icon: {
            new: "fas fa-table-columns",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    BoxLoadCheckLink: {
        appDomain: "beta",
        uri: `/d/box-load-check`,
        uriRegex: [/\/d\/box-load-check/g],
        label: {
            new: "Box Load Check",
        },
        icon: {
            new: "fas fa-box-check",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    PrimaryConnectLink: {
        appDomain: "beta",

        uriRegex: [
            /\/d\/primary-connect\/(.*)\/rg\/(.*)\/rz\/(.*)\/z\/(.*)\/c\/(.*)\/status\/(.*)/g,
        ],
        label: {
            new: "Primary Connect",
        },
        icon: {
            new: "fas fa-box-check",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    PalletsLink: {
        appDomain: "beta",
        uri: `/pallet/pallets/all`,
        uriRegex: [/\/pallet\/pallets\/(.*)/g],
        label: {
            old: "Pallets",
            new: "Pallets",
        },
        icon: {
            old: "fas fa-pallet",
            new: "fas fa-pallet",
        },
        showBetaBadge: {
            old: false,
            new: false,
        },
    },
    PalletsSummaryLink: {
        appDomain: "beta",
        uri: `/pallet/summary`,
        uriRegex: [/\/pallet\/summary\/(.*)/g],
        label: {
            old: "Pallets Summary",
            new: "Pallets Summary",
        },
        icon: {
            old: "fas fa-pallet",
            new: "fas fa-pallet",
        },
        showBetaBadge: {
            old: false,
            new: false,
        },
    },
};
