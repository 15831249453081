import NavLink from "../components/SiteNavLink";
import uuid from "app/util/uuid";
import { navLinks } from "shared/navLinks";

class SiteNavLinkBuilder {
    constructor(
        user_role,
        currentClientId,
        currentDate,
        userHasMultipleClients
    ) {
        this.links = navLinks.links;
        this.titleMap = navLinks.titleMap;
        this.links.ClientViewErrorsLink.label = {
            old: user_role === "admin" ? "Client View Errors" : "Errors",
            new: user_role === "admin" ? "Client View Errors" : "Errors",
        };
        this.links.ErrorFailureReportLink.uri = `/cd/${
            user_role === "admin" ? currentClientId + "/" : ""
        }d/error-and-failure-report`;
        this.links.LegacyOrderSearchLink.uri = `/cd/${
            user_role === "admin" ? currentClientId + "/" : ""
        }d/deliveries`;
        this.links.OrderETALink.uri = `/cd/${
            user_role === "admin" ? currentClientId + "/" : ""
        }d/eta`;
        this.links.ClientSummaryReportLink.uri = `/cd/${
            user_role === "admin" ? currentClientId + "/" : ""
        }d/client-summary`;
        this.links.ZoneSuburbReportLink.uri = `z/${
            user_role === "admin" ? currentClientId + "/" : ""
        }zone-suburb-report`;
        this.links.CustomerNotificationSettingsLink.uri = `/cs/${
            user_role === "admin" ? currentClientId + "/cns" : "cns"
        }`;
        this.links.ForecastRecipesLink.uri = `/d/client-tools/${
            user_role === "admin" || userHasMultipleClients
                ? currentClientId + "/"
                : ""
        }orders/recipes`;
        this.links.SummaryReportLink.uri = `/packer/${
            user_role === "admin" || userHasMultipleClients
                ? currentClientId + "/"
                : ""
        }reports/summary`;
        this.links.PickerPerformanceReportsLink.uri = `/p/picker-performance/${
            user_role === "admin" || userHasMultipleClients
                ? currentClientId + "/"
                : ""
        }`;
        this.links.RDSRoutingToolLink.uri = `/d/visualisation/date/${currentDate}/rg/all/rz/all/z/all/client/all/service/rds/tw/all`;
        this.links.BetaContractorAssignLink.uri = `/run/assign/contractorN/1/${currentDate}/rg/all/all`;
        this.links.BetaDriverAssignLink.uri = `/run/assign/driverN/1/${currentDate}/rg/all/all`;
        this.links.RunSummaryLink.uri = `/run/r/country/1/${currentDate}`;
        this.links.PrimaryConnectLink.uri = `/d/primary-connect/${currentDate}/rg/all/rz/all/z/all/c/all/status/all`;
        this.links.PickSheetOrderLink.uri = `/client-tools/${
            currentClientId + "/"
        }pick-sheet-order`;
        this.links.PickSheetOrderLink.showBetaBadge = {
            old: true,
            new: true,
        };
        this.links.DashboardLink.showBetaBadge = {
            old: true,
            new: true,
        };
        this.links.WholesaleDeliveriesLink.label = {
            old: "WS Deliveries Confirmation",
            new: "WS Deliveries Confirmation",
        };
        this.links.OrderSearchLink.showBetaBadge = {
            old: true,
            new: true,
        };
        this.links.NewOrderETALink.showBetaBadge = {
            old: true,
            new: true,
        };
    }

    getLinkAppDomain = (type) => {
        return this.links[type].appDomain ?? "legacy";
    };
    getLinkURL = (type) => {
        return this.links[type].uri ?? { uri: "/no-access" };
    };
    getLabel = (type, variant = "old") => {
        let label = null;
        try {
            label =
                this.links[type]["label"][variant] ??
                this.links[type]["label"]["old"];
        } catch (e) {
            label = null;
        }

        return label;
    };
    getIcon = (type, variant = "old") => {
        let icon = null;
        try {
            icon =
                this.links[type]["icon"][variant] ??
                this.links[type]["icon"]["old"];
        } catch (e) {
            icon = null;
        }
        return icon;
    };
    getTooltip = (type, variant = "old") => {
        let tooltip = null;
        try {
            tooltip = this.links[type]["tooltip"][variant];
        } catch (e) {
            tooltip = null;
        }

        const tooltipStyle = this.links[type]["tooltipColor"]
            ? { color: this.links[type]["tooltipColor"] }
            : null;

        return tooltip ? (
            <i
                style={tooltipStyle}
                className="fas fa-info-circle menu-tooltip"
                title={tooltip}
            ></i>
        ) : (
            ""
        );
    };
    getBetaBadge = (type, useNewLabel) => {
        let version = useNewLabel ? "new" : "old";
        let showBetaBadge = false;
        try {
            showBetaBadge = this.links[type]["showBetaBadge"][version];
        } catch (e) {
            showBetaBadge = false;
        }
        return showBetaBadge ? <span className="badge mr-2">Beta</span> : "";
    };
    generateLink = (
        type,
        notifications,
        useNewLabel = false,
        useNewIcon = false
    ) => {
        return (
            <NavLink
                key={uuid()}
                to={this.getLinkURL(type)}
                type={this.getLinkAppDomain(type)}
            >
                <button
                    data-url="dashboard"
                    data-notifications={notifications ?? 0}
                    className="nav-button"
                >
                    {useNewIcon ? (
                        <>
                            {this.getIcon(type, "new") && (
                                <i className={this.getIcon(type, "new")}></i>
                            )}
                        </>
                    ) : (
                        <>
                            {this.getIcon(type) && (
                                <i className={this.getIcon(type)}></i>
                            )}
                        </>
                    )}
                    {this.getLinkAppDomain(type) === "beta" &&
                        this.getBetaBadge(type, useNewLabel)}
                    {useNewLabel ? (
                        <span>
                            {this.getLabel(type, "new")}{" "}
                            {this.getTooltip(type, "new")}
                        </span>
                    ) : (
                        <span>
                            {this.getLabel(type)} {this.getTooltip(type)}
                        </span>
                    )}
                </button>
            </NavLink>
        );
    };
    getUriTitleMap = () => {
        let uriTitleMap = [];
        Object.keys(this.links).forEach((key) => {
            if (this.links[key].uriRegex) {
                this.links[key].uriRegex.forEach((regex) => {
                    uriTitleMap.push({
                        path: regex,
                        title: this.getLabel(key, "new") ?? this.getLabel(key),
                    });
                });
            }
        });
        return [...this.titleMap, ...uriTitleMap];
    };
}

export default SiteNavLinkBuilder;
