import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';

import NotFound from './NotFound';
import { PageNotFound } from 'app/common/pages/ErrorPage';
import formatCrossLink from 'app/util/formatCrossLink';

const UserSection = Loadable({
    loader: () => import(/* webpackChunkName: "user-section" */ './scan/Routes'),
    loading: () => null,
    modules: ['user-section'],
    webpack: () => [require.resolveWeak('./scan/Routes')] // Prevents white flash
});

const ClientDeliveriesSection = Loadable({
    loader: () => import(/* webpackChunkName: "client-deliveries-section" */ './clientDeliveries/Routes'),
    loading: () => null,
    modules: ['client-deliveries-section'],
    webpack: () => [require.resolveWeak('./clientDeliveries/Routes')] // Prevents white flash
});

const ClientUsersSection = Loadable({
    loader: () => import(/* webpackChunkName: "client-users-section" */ './clientUsers/Routes'),
    loading: () => null,
    modules: ['client-users-section'],
    webpack: () => [require.resolveWeak('./clientUsers/Routes')] // Prevents white flash
});

const ClientSettingsSection = Loadable({
    loader: () => import(/* webpackChunkName: "client-settings-section" */ './clientSettings/Routes'),
    loading: () => null,
    modules: ['client-settings-section'],
    webpack: () => [require.resolveWeak('./clientSettings/Routes')] // Prevents white flash
});

const DespatchSection = Loadable({
    loader: () => import(/* webpackChunkName: "despatch-section" */ './despatch/Routes'),
    loading: () => null,
    modules: ['despatch-section'],
    webpack: () => [require.resolveWeak('./despatch/Routes')] // Prevents white flash
});

const ContractorsSection = Loadable({
    loader: () => import(/* webpackChunkName: "contractors-section" */ './contractors/Routes'),
    loading: () => null,
    modules: ['contractors-section'],
    webpack: () => [require.resolveWeak('./contractors/Routes')] // Prevents white flash
});

const SpecialSection = Loadable({
    loader: () => import(/* webpackChunkName: "special-section" */ './special/Routes'),
    loading: () => null,
    modules: ['special-section'],
    webpack: () => [require.resolveWeak('./special/Routes')] // Prevents white flash
});

const ZonesSection = Loadable({
    loader: () => import(/* webpackChunkName: "zones" */ './zones/Routes'),
    loading: () => null,
    modules: ['zones'],
    webpack: () => [require.resolveWeak('./zones/Routes')]
});

const ClientToolsSection = Loadable({
    loader: () => import(/* webpackChunkName: "despatch-section" */ './client-tools/Routes'),
    loading: () => null,
    modules: ['client-tools-section'],
    webpack: () => [require.resolveWeak('./client-tools/Routes')] // Prevents white flash
});

const SMSSection = Loadable({
    loader: () => import(/* webpackChunkName: "despatch-section" */ './smsMessages/Routes'),
    loading: () => null,
    modules: ['sms-section'],
    webpack: () => [require.resolveWeak('./smsMessages/Routes')] // Prevents white flash
});

const NewVersionSection = Loadable({
    loader: () => import(/* webpackChunkName: "nv" */ '../nv/sections/Routes'),
    loading: () => null,
    modules: ['nv'],
    webpack: () => [require.resolveWeak('../nv/sections/Routes')]
});

const PackerSection = Loadable({
    loader: () => import(/* webpackChunkName: "packer-section" */ '../nv/sections/packer/Routes'),
    loading: () => null,
    modules: ['packer-section'],
    webpack: () => [require.resolveWeak('../nv/sections/packer/Routes')] // Prevents white flash
});

const BetaSection = Loadable({
    loader: () => import(/* webpackChunkName: "beta-section" */ '../nv/sections/beta/Routes'),
    loading: () => null,
    modules: ['beta-section'],
    webpack: () => [require.resolveWeak('../nv/sections/beta/Routes')] // Prevents white flash
});

export default React.memo(({ auth }) => {

    return <Switch>
        <Route path="/scan" component={UserSection} />
        <Route path="/cd" component={ClientDeliveriesSection} />
        <Route path="/cu" component={ClientUsersSection} />
        <Route path="/cs" component={ClientSettingsSection} />
        <Route path="/ct" component={ContractorsSection} />
        <Route path="/despatch" component={DespatchSection} />
        <Route path="/special" component={SpecialSection} />
        <Route path="/zones" component={ZonesSection} />
        <Route path="/client-tools" component={ClientToolsSection} />
        <Route path="/sms" component={SMSSection} />
        <Route path="/nv" component={NewVersionSection} />
        <Route path="/packer" component={PackerSection} />
        <Route path="/beta" component={BetaSection} />

        <Route key="logout" path={`/auth/logout`} component={require('app/modules/authentication/section/authentication/page/Logout').default} />
        {/* <Route key="unauthorized" path={`/auth/unauthorized`} component={require('app/modules/authentication/page/Unauthorized').default} /> */}

        <Route
            exact path="/"
            component={() => {
                window.location = formatCrossLink('/d/overview', auth.token);
                return null;
            }}
        />

        <Route path="/*" component={PageNotFound} />
    </Switch>

});