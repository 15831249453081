
const userLevelKeys = {
    admin: [
    ],
    'admin-dashboard-only': [
        'all-dashboard'
    ],
    user: [
        'order-search',
        'drops'
    ],
    'dashboard-only': [
        'dashboard'
    ],
    'contractor': [
        'user-management',
        'critical-issues'
    ],
    'client-admin': [
        'upload-manifest',
        'client-tools-picksheet-order',
        'customer-notification-settings',
        'client-settings',
        'user-management',
        'order-search',
        'client-tools',
        'client-tools-export-items',
        'drops',
        'runs',
        'dashboard',
        'zone-suburb-report',
        'pre-delivery',
        'shopify-export',
        'zones-client',
        'packer',
        'purchase-orders'
    ],
    'client-user': [
        'order-search',
        'drops',
        'runs',
        'client-tools',
        'client-tools-export-items',
        'dashboard',
        'pre-delivery'
    ],

};

const arrayUniqueValue = (arr) => {
    return Array.from(new Set(arr))
}

export const userHasAccess = (role, key) => {

    if (role === 'admin') {
        return true;
    }

    const section = userLevelKeys[role];
    if (section === undefined) {
        return false;
    }

    return section.includes(key);
};

export const userHasAccessOneOf = (role, keys) => {

    let hasAccess = false;
    if (Array.isArray(keys)) {
        keys.forEach(key => {
            if (userHasAccess(role, key)) {
                hasAccess = true;
            }
        });
    }

    return hasAccess;
};

export const userIsNotContractor = (role) => {
    return role !== 'contractor';
}

export const userIsHDSStaff = (role) => {
    return role === 'admin' || role === 'admin-dashboard-only'
};

export const userIsAClient = (role) => {
    return !userIsHDSStaff(role);
};

export const userIsContractor = (role) => {
    return role === 'contractor';
}

export const userHasFeature = (user, featureName, featureValue) => {
    if (! user.features[featureName]) {
        return false;
    }

    if (featureValue === '*') {
        return true;
    }

    const userFeatureOption = user.features[featureName];
    if (userFeatureOption.toString().indexOf(',') !== -1) {
        const values = userFeatureOption.split(',').map(i => i.trim());
        if (values.includes(featureValue)) {
            return true;
        }
    }

    return user.features[featureName] == featureValue;
}

export const userIsAMemberOfClientId = (user, clientId) => {
    const clientIds = userClientIds(user);
    return clientIds.includes(clientId);
}

export const userHasMultipleClients = (user) => {

    return userClientIds(user).length > 1;
}

export const userClientIds = (user) => {

    const clientIds = user.userData.clientIds;

    if (clientIds === null || clientIds === undefined) {
        return [user.clientId];
    }

    return clientIds;
}

export const usersCurrentClientId = (user, clientId) => {

    if (user.role === 'admin' || user.role === 'admin-dashboard-only') {
        return clientId;
    }

    const myClientIds = userClientIds(user);

    const findClientId = myClientIds.filter(item => {
        return item === clientId;
    });

    if (findClientId.length > 0) {
        return findClientId[0];
    }

    if (arrayUniqueValue(myClientIds).length < 2 && user.clientId) {
        return user.clientId;
    }

    return -1;
}

export const userIsDev = (user) => {
    return userHasFeature(user, 'Developer', '*');
}


export const userIsProvidoorMain = (user) => {
    return user.clientId === 341;
}

export const clientHasPackerToolsAccess = (client) => {
    return client?.id === -1 || client?.configuration?.packerToolsEnabled;
}
export const clientHasPackerEnabled = (client) => {
    return client?.configuration?.packerStrategy !== null;
}
export const clientHasPackerSummaryReportAccess = (client) => {
    return client?.id === -1 || client?.configuration?.packerSummaryReportEnabled;
}
export const clientHasPackerProductSummaryReportAccess = (client) => {
    return client?.id === -1 || client?.configuration?.packerProductSummaryReportEnabled;
}
export const clientHasPackerPickerPerformanceReportAccess = (client) => {
    return client?.id === -1 || client?.configuration?.packerPickerPerformanceReportEnabled;
}
export const clientHasPackerYouFoodzDailyReportAccess = (client) => {
    return client?.id === -1 || (client?.configuration?.packerSummaryReportEnabled && client?.id === 8);
}

export const clientsHaveIntegration = (clients, integrationName) => {

    if (!clients) {
        return false;
    }

    return clients.some(c => c.integrationServices && c.integrationServices.includes(integrationName));
}

export const clientHasIntegration = (client, integrationName) => {
    return client && client.integrationServices && client.integrationServices.includes(integrationName);
}

export const clientsHaveConfiguration = (clients, fieldName, fieldValue) => {

    if (!clients) {
        return false;
    }

    return clients.some(c => c.configuration && c.configuration[fieldName] !== undefined && c.configuration[fieldName] === fieldValue);
}