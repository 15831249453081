import * as React from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import { matchPath } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { navColors } from "shared/theme";

import {
    clientHasPackerPickerPerformanceReportAccess,
    clientHasPackerProductSummaryReportAccess,
    clientHasPackerSummaryReportAccess,
    clientHasPackerToolsAccess,
    clientsHaveConfiguration,
    clientsHaveIntegration,
    userHasAccess,
    userHasFeature,
    userHasMultipleClients,
    userIsAMemberOfClientId,
    userIsContractor,
    userIsDev,
    userIsHDSStaff,
    usersCurrentClientId,
} from "../util/hasAccess";

/* @jsxImportSource @emotion/react */
import { css } from "@emotion/react"; /* eslint-disable-line */
import formatDate from "date-fns/format";
import config from "app/config";
import useExpandedMenu from "app/common/useExpandedMenu";

import NavLink from "./SiteNavLink";
import SiteNavLinkBuilder from "app/common/SiteNavLinkBuilder";

import TextInput from "app/nv/components/form/TextInput";
import { alertColors, colors } from "app/common/arctechv2/theme";
import registerStateRedux from "app/resources/state";
import registerRoutingGroupRedux from "app/nv/resources/routingGroup";
import registerRoutingZoneRedux from "app/nv/resources/routingZone";
import registerCountryRedux from "app/resources/country";
import registerDepotRedux from "app/nv/resources/depot";
import ArtechIcon from "./svgIcon/ArtechIcon";

const stateRedux = registerStateRedux("STATE_LIST", ["LIST"]);
const routingGroupRedux = registerRoutingGroupRedux("ROUTING_GROUP_LIST", [
    "LIST",
]);
const routingZoneRedux = registerRoutingZoneRedux("ROUTING_ZONE_LIST", [
    "LIST",
]);
const countryRedux = registerCountryRedux("COUNTRY_LIST", ["LIST"]);
const depotRedux = registerDepotRedux("DEPOT_LIST", ["LIST"]);

const isDevelopment = process.env.NODE_ENV === "development";
const isSandbox =
    process.env.REACT_APP_BETA_URI ===
    "https://beta-dashboard.sandbox.homedelivery.com.au";

const containerCss = css`
    button:hover {
        cursor: pointer;
    }

    * {
        font-family: "Inter", sans-serif;
    }

    width: 280px;
    flex: 0 0 auto;
    height: 100%;
    background: ${isSandbox || isDevelopment
        ? navColors.sandboxNav
        : navColors.navBlue};
    position: relative;
    transition: width 0.4s;
    transform: translate3d(0, 0, 0);

    &.is-closed {
        width: 0;

        .wrap {
            transform: translateX(-100%);
        }
    }

    .nav-button {
        display: flex;
        align-items: center;
    }

    .nav-button--collapse-toggle {
        position: relative;
        > button {
            position: relative;
        }
        h5 {
            padding: 0 !important;
            position: relative;
        }
    }

    .sidemenu-trigger {
        background: ${isSandbox || isDevelopment
            ? navColors.sandboxNav
            : navColors.navBlue};
        font-size: 14px;
        padding: 10px 0;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 50px;
        left: 100%;
        z-index: 20;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        display: flex;
        color: #fff;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border: 0;

        > i {
            transition: transform 0.3s, -webkit-transform 0.3s;
        }
    }

    .wrap {
        width: 280px;
        overflow-y: auto;
        height: 100%;
        opacity: 1;
        visibility: visible;
        transition: transform 0.4s;
        transition: transform 0.4s, -webkit-transform 0.4s;

        > .logo {
            padding: 20px;
            padding-bottom: 0;
            > img {
                width: 100%;
                display: block;
            }
        }
    }

    .receival {
        button {
            span {
                font-size: 13px;
                font-weight: 600;
                color: #fff;
            }
        }
    }

    .submenu {
        padding: 0 0 10px;
        border-bottom: 1px solid #62626255;
        position: relative;

        .collapse-toggle {
            position: absolute;
            top: 0;
            right: 0;
            text-align: right;
            z-index: 1;
            width: auto !important;
        }

        &:last-of-type {
            border-bottom: none;
        }

        &:empty {
            border-bottom: none;
            padding-bottom: 0;
        }

        &.no-padding-bottom {
            padding-bottom: 0;
        }

        h5 {
            padding: 10px 20px;
            font-weight: 700;
            font-size: 12px;
            text-transform: uppercase;
            color: #fff;
            margin: 0;
        }

        a {
            &.active {
                button {
                    background: #374151;
                }
            }
        }

        button {
            font-weight: 300;
            font-size: 14px;
            text-align: left;
            color: #b8b8b8;
            width: 100%;
            padding: 10px 20px;
            position: relative;
            border: 0;
            background: 0 0;
            outline: 0;

            &:hover {
                background: #374151;
            }

            &::after {
                content: "\f054";
                display: inline-block;
                right: 15px;
                font-family: "Font Awesome 5 Free", sans-serif;
                font-weight: 900;
                font-size: 8px;
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                line-height: 1;
                color: #fff;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }

            i {
                width: 16px;
                margin: 0 12px 0 0;
                text-align: center;
                color: #fff;
                vertical-align: top;
            }

            i.menu-tooltip {
                color: #1bc5bd;
            }
        }

        button[data-notifications-warning]::before {
            background: yellow !important;
        }

        button[data-notifications]::before {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-weight: 600;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            content: attr(data-notifications);
            min-width: 38px;
            height: 18px;
            left: 4px;
            text-align: center;
            font-size: 10px;
            border-radius: 8px;
            z-index: 2;
            background: ${alertColors.textDanger};
        }

        button[data-notifications="0"]::before {
            opacity: 0;
        }

        .stats {
            margin: 10px 0 20px;
            padding: 0 20px;

            .text {
                width: 100%;
                display: flex;
                justify-content: space-between;

                span {
                    font-weight: 300;
                    font-size: 14px;
                    text-align: left;
                    color: #b8b8b8;

                    &.percents {
                        font-weight: 700;
                        color: #fff;

                        &::before {
                            content: attr(data-percent);
                        }
                    }
                }
            }

            .percent-bar {
                width: 100%;
                height: 4px;
                margin: 15px 0 0;
                position: relative;
                background: #111a21;
                border-radius: 2px;

                &:after {
                    background: #00adee;
                    height: 4px;
                    position: absolute;
                    left: 0;
                    border-radius: 2px;
                    content: "";
                    display: block;
                }
            }
        }
    }

    .hide {
        display: none;
    }

    .state-link {
        display: block;

        i {
            display: inline-block;
            vertical-align: top;
            margin-top: 2px;
        }

        div {
            display: inline-block;
            width: calc(100% - 33px);
        }
    }

    .logo-img {
        width: auto !important;
        height: auto;
        max-width: 140px;
        max-height: 50px;
        display: block;
        margin: 0 auto;
    }

    .badge {
        display: inline-block;
        padding: 0.35em 0.65em;
        font-size: 10px !important;
        font-weight: 600;
        line-height: 1;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 0.25rem;
        border-radius: 50rem;
        text-transform: uppercase;
        background-color: #8950fc;
    }

    .no-child {
        display: none;
    }

    .menu-search {
        position: relative;
        padding: 0 1.5rem;
        display: flex;
        align-items: center;

        label {
            display: none;
        }

        .text-field {
            width: 100%;
            input {
                outline: none;
            }
        }

        .text-field-clear {
            position: absolute;
            font-size: 1.5rem;
            color: #bdbdbd;
            right: 2rem;
            cursor: pointer;
            outline: none;
            &:hover {
                color: #8e8e8e;
            }
        }

        > div {
            width: 100%;
        }
    }

    input[name="searchKey"] {
        border: 1px solid ${colors.gray600} !important;
        background: ${colors.gray700} !important;
        color: #fff !important;

        &:focus {
            outline: 1px solid ${colors.gray400} !important;
            border: 1px solid ${colors.gray600} !important;
        }
        &::placeholder {
            color: ${colors.gray400} !important;
        }
    }
`;

const SiteNavigation = (props) => {
    const auth = useSelector((state) => state.auth);
    const [expandedMenu, setExpandedMenu] = useExpandedMenu();

    const [showing, setShowing] = useState(true);

    const showNewMenu = true;
    const clients = useSelector((state) => state.CLIENT_LIST);
    const notifications = useSelector(
        (state) => state.dashboardActivity
    )?.activity;
    const dispatch = useDispatch();

    const match = matchPath(props.location.pathname, {
        path: "/:module/:clientId",
        exact: false,
        strict: false,
    });

    let currentClientId = usersCurrentClientId(
        auth.user,
        match?.params.clientId ? parseInt(match?.params.clientId) : undefined
    );

    if (isNaN(currentClientId)) {
        currentClientId = -1;
    }

    const handleNavScroll = () => {
        const _expandedMenu =
            JSON.parse(window?.localStorage?.getItem("expandedMenu")) ??
            expandedMenu;
        setExpandedMenu({
            ..._expandedMenu,
            scroll: sideNavRef.current.scrollTop,
        });
    };
    const currentClient = clients.items?.find((client) => {
        return client.id === currentClientId;
    });
    const showNav =
        currentClient || (currentClientId == -1 && auth.user.role === "admin");
    let canUploadCsv = false;
    if (userIsHDSStaff(auth.user.role)) {
        canUploadCsv = true;
    } else {
        canUploadCsv =
            clients.items?.filter((client) => {
                return client.configuration?.allowCsvManifestUpload === true;
            }).length > 0;
    }

    const sideNavRef = useRef(null);
    let debounceTimeout = null;

    useEffect(() => {
        if (sideNavRef.current) {
            if (window.setSideNavScrollTop) {
                setTimeout(() => {
                    sideNavRef.current.scrollTo({
                        top: window.setSideNavScrollTop,
                    });
                    delete window.setSideNavScrollTop;
                }, 50);
            }

            function onScroll() {
                if (debounceTimeout) clearTimeout(debounceTimeout);
                debounceTimeout = setTimeout(() => {
                    handleNavScroll();
                }, 500);
            }
            sideNavRef.current.addEventListener("scroll", onScroll);

            return () => {
                sideNavRef?.current?.removeEventListener("scroll", onScroll);
            };
        }
    }, [sideNavRef.current]);

    useEffect(() => {
        dispatch(
            countryRedux.actionCreators.list(auth, {
                limit: -1,
            })
        );
        dispatch(
            stateRedux.actionCreators.list(auth, {
                limit: -1,
            })
        );
        dispatch(
            depotRedux.actionCreators.list(auth, {
                limit: -1,
            })
        );
        dispatch(
            routingGroupRedux.actionCreators.list(auth, {
                limit: -1,
                sort: "countryId,name",
            })
        );
        dispatch(
            routingZoneRedux.actionCreators.list(auth, {
                limit: -1,
            })
        );
    }, []);

    const siteNavItems = useMemo(() => {
        const errorReportingClientIds = [8, 14, 363];

        const canViewZones =
            clients.items?.filter((client) => {
                return client.configuration?.allowViewingZones === true;
            }).length > 0;

        return [
            {
                condition: showNav,
                classNames: "deliveries receival pb-0",
                items: [
                    {
                        type: "linkNew",
                        component: "OrderSearchLink",
                        condition: userHasAccess(
                            auth.user.role,
                            "order-search"
                        ),
                    },
                ],
            },
            {
                condition: showNav,
                classNames: "deliveries receival pb-0",
                items: [
                    {
                        type: "heading",
                        text: "Deliveries",
                    },
                    {
                        type: "linkNew",
                        component: "DashboardLink",
                    },
                    {
                        type: "component",
                        component: "DeliveriesByStateNoHeadingLink",
                    },
                    {
                        type: "linkNew",
                        component: "DeliveryErrorsLink",
                        condition:
                            userHasAccess(auth.user.role, "errors") ||
                            errorReportingClientIds.includes(
                                auth.user?.clientId
                            ),
                    },
                    {
                        type: "linkNew",
                        component: "RunDropErrorsLink",
                        condition:
                            userHasAccess(auth.user.role, "errors") ||
                            (userIsContractor(auth.user.role) &&
                                userHasFeature(
                                    auth.user,
                                    "ContractorRunDropErrors",
                                    "*"
                                )),
                    },
                    {
                        type: "link",
                        component: "ClientViewErrorsLink",
                        condition: userHasFeature(
                            auth.user,
                            "ClientViewErrors",
                            "1"
                        ),
                    },
                    {
                        type: "linkNew",
                        component: "BetaDeliveriesLink",
                        condition:
                            config.betaURI && auth.user.role !== "contractor",
                    },
                    {
                        type: "linkNew",
                        component: "BetaDeliveriesGeoIssueLink",
                        condition:
                            config.betaURI && auth.user.role !== "contractor",
                    },
                    {
                        type: "linkNew",
                        component: "RunsLink",
                        condition:
                            config.betaURI &&
                            (auth.user.role == "admin" ||
                                (userIsContractor(auth.user.role) &&
                                    userHasFeature(
                                        auth.user,
                                        "ContractorManageRuns",
                                        "*"
                                    ))),
                    },
                    {
                        type: "linkNew",
                        component: "RunRulesLink",
                        condition:
                            config.betaURI &&
                            auth.user.role == "admin" &&
                            (isDevelopment || isSandbox),
                    },
                    {
                        type: "linkNew",
                        component: "BetaBoxesLink",
                        condition:
                            config.betaURI &&
                            clientsHaveIntegration(clients.items, "auspost"),
                    },
                    {
                        type: "linkNew",
                        component: "RunSummaryLink",
                        condition:
                            config.betaURI &&
                            (userIsHDSStaff(auth.user.role) ||
                                userIsContractor(auth.user.role) ||
                                auth.user.role === "client-admin" ||
                                auth.user.role === "client-user"),
                    },
                    {
                        type: "linkNew",
                        component: "LoadoutScreenLink",
                        condition:
                            config.betaURI &&
                            (userIsHDSStaff(auth.user.role) ||
                                userIsContractor(auth.user.role)),
                    },
                    {
                        type: "linkNew",
                        component: "PostSortationBoxValidationLink",
                        condition:
                            config.betaURI &&
                            userIsHDSStaff(auth.user.role) &&
                            userHasFeature(
                                auth.user,
                                "PostSortationScreen",
                                "1"
                            ),
                    },
                    {
                        type: "linkNew",
                        component: "BoxLoadCheckLink",
                        condition:
                            config.betaURI &&
                            (userIsHDSStaff(auth.user.role) ||
                                userIsAMemberOfClientId(auth.user, 888)),
                    },
                    {
                        type: "linkNew",
                        component: "PrimaryConnectLink",
                        condition:
                            config.betaURI && userIsHDSStaff(auth.user.role),
                    },
                    {
                        type: "linkNew",
                        component: "RetailDeliveryBookingsLink",
                        condition:
                            config.betaURI &&
                            userHasFeature(
                                auth.user,
                                "RetailDeliveryBooking",
                                "1"
                            ),
                    },
                    {
                        type: "linkNew",
                        component: "PalletsLink",
                        condition:
                            config.betaURI && userIsHDSStaff(auth.user.role),
                    },
                    {
                        type: "linkNew",
                        component: "PalletsSummaryLink",
                        condition:
                            config.betaURI && userIsHDSStaff(auth.user.role),
                    },
                    {
                        type: "linkNew",
                        component: "AppLogLink",
                        condition: config.betaURI && userIsDev(auth.user),
                    },
                ],
            },
            {
                condition: showNav,
                classNames: "deliveries receival pb-0",
                items: [
                    {
                        type: "heading",
                        text: "Delivery Confirmations",
                    },
                    {
                        type: "linkNew",
                        component: "WholesaleDeliveriesLink",
                        condition: userHasAccess(
                            auth.user.role,
                            "wholesale-deliveries"
                        ),
                    },
                    {
                        type: "linkNew",
                        component: "HomeDeliveriesConfirmLink",
                        condition: userHasAccess(
                            auth.user.role,
                            "home-deliveries-confirm"
                        ),
                    },
                    {
                        type: "linkNew",
                        component: "TemperatureCheckConfirmationLink",
                        condition: userHasAccess(
                            auth.user.role,
                            "temp-check-confirmation"
                        ),
                    },
                    {
                        type: "link",
                        component: "DropInstructionsFollowedLink",
                        condition: userHasAccess(
                            auth.user.role,
                            "drop-instructions-followed"
                        ),
                    },
                    {
                        type: "linkNew",
                        component: "CustomerChangeRequestsLink",
                        condition: userHasAccess(
                            auth.user.role,
                            "customer-updates"
                        ),
                        notifications:
                            notifications?.customerChangeRequests || 0,
                    },
                    {
                        type: "linkNew",
                        component: "CustomerChangeRequestsLinkBeta",
                        condition: userHasAccess(
                            auth.user.role,
                            "customer-updates"
                        ),
                        notifications:
                            notifications?.deliveryCustomerChangeRequests || 0,
                    },
                    {
                        type: "linkNew",
                        component: "ZoneLookupLink",
                    },
                ],
            },
            {
                condition: showNav,
                classNames: "deliveries receival pb-0",
                items: [
                    {
                        type: "heading",
                        text: "Reports",
                    },
                    {
                        type: "linkNew",
                        component: "DeliveriesSummaryLink",
                        condition:
                            showNav &&
                            userHasAccess(auth.user.role, "dashboard-summary"),
                    },
                    {
                        type: "linkNew",
                        component: "AllClientForecastDeliveriesLink",
                        condition: showNav && userIsHDSStaff(auth.user.role),
                    },
                    {
                        type: "linkNew",
                        component: "ErrorRateLink",
                        condition:
                            userHasAccess(auth.user.role, "error-rate") ||
                            errorReportingClientIds.includes(
                                auth.user.clientId
                            ),
                    },
                    {
                        type: "linkNew",
                        component: "ErrorFailureReportLink",
                        condition:
                            userHasAccess(auth.user.role, "error-rate") ||
                            errorReportingClientIds.includes(
                                auth.user.clientId
                            ),
                    },
                    {
                        type: "linkNew",
                        component: "TimeWindowReportOverviewLink",
                        condition: userHasAccess(
                            auth.user.role,
                            "order-search"
                        ),
                    },
                    {
                        type: "linkNew",
                        component: "NewOrderETALink",
                        condition: userHasAccess(
                            auth.user.role,
                            "order-search"
                        ),
                    },
                    {
                        type: "linkNew",
                        component: "ZoneSuburbReportLink",
                        condition: userHasAccess(
                            auth.user.role,
                            "zone-suburb-report"
                        ),
                    },
                    {
                        type: "linkNew",
                        component: "CustomerFeedbackLink",
                        condition:
                            config.betaURI && userIsHDSStaff(auth.user.role),
                    },
                    {
                        type: "link",
                        component: "CustomerFeedbackNLink",
                        condition:
                            config.betaURI &&
                            userIsHDSStaff(auth.user.role) &&
                            userHasFeature(
                                auth.user,
                                "NewCustomerFeedback",
                                "*"
                            ),
                    },
                    {
                        type: "linkNew",
                        component: "AuspostDataReportLink",
                        condition:
                            config.betaURI &&
                            clientsHaveIntegration(clients.items, "auspost"),
                    },
                    {
                        type: "link",
                        component: "BetaStartrackBoxesLink",
                        condition:
                            config.betaURI &&
                            clientsHaveIntegration(
                                clients.items,
                                "startrack"
                            ) &&
                            userHasFeature(auth.user, "Startrack", 1),
                    },
                    {
                        type: "link",
                        component: "StartrackManifestsLink",
                        condition:
                            config.betaURI &&
                            clientsHaveIntegration(
                                clients.items,
                                "startrack"
                            ) &&
                            userHasFeature(auth.user, "Startrack", 1),
                    },
                    {
                        type: "link",
                        component: "StartrackDataReportLink",
                        condition:
                            config.betaURI &&
                            clientsHaveIntegration(
                                clients.items,
                                "startrack"
                            ) &&
                            userHasFeature(auth.user, "Startrack", 1),
                    },
                    {
                        type: "linkNew",
                        component: "BetaAPReportLink",
                        condition:
                            config.betaURI &&
                            clientsHaveIntegration(clients.items, "auspost"),
                    },
                    {
                        type: "link",
                        component: "ContractorPaySummaryLink",
                        condition:
                            config.betaURI &&
                            auth.user.role === "contractor" &&
                            userHasFeature(
                                auth.user,
                                "ContractorAccessPaySummary",
                                "1"
                            ),
                    },
                    {
                        type: "link",
                        component: "ContractorPerformanceReportLink",
                        condition:
                            config.betaURI &&
                            auth.user.role === "admin" &&
                            userHasFeature(
                                auth.user,
                                "ContractorPerformanceReport",
                                "1"
                            ),
                    },
                ],
            },
            {
                condition: showNav,
                classNames: "deliveries receival pb-0",
                items: [
                    {
                        type: "heading",
                        text: "Admin",
                    },
                    {
                        type: "linkNew",
                        component: "SMSScheduleLink",
                        condition: userHasAccess(
                            auth.user.role,
                            "sms-schedule"
                        ),
                    },
                    {
                        type: "link",
                        component: "SMSBlockLink",
                        condition: userHasAccess(auth.user.role, "sms-block"),
                    },
                    {
                        type: "link",
                        component: "UnsubscribeEmailLink",
                        condition: userHasAccess(
                            auth.user.role,
                            "email-unsubscribes"
                        ),
                    },
                ],
            },
            {
                condition:
                    showNav &&
                    userIsHDSStaff(auth.user.role) &&
                    userHasFeature(auth.user, "FinancialReporting", "*"),
                classNames: "deliveries receival pb-0",
                items: [
                    {
                        type: "heading",
                        text: "Financial Reporting",
                    },
                    {
                        type: "linkNew",
                        component: "FRImportRatesLink",
                        condition:
                            userHasFeature(
                                auth.user,
                                "FinancialReporting",
                                "all"
                            ) ||
                            userHasFeature(
                                auth.user,
                                "FinancialReporting",
                                "import-rates"
                            ),
                    },
                    {
                        type: "linkNew",
                        component: "FRRateMasterLink",
                        condition:
                            userHasFeature(
                                auth.user,
                                "FinancialReporting",
                                "all"
                            ) ||
                            userHasFeature(
                                auth.user,
                                "FinancialReporting",
                                "rate-master"
                            ),
                    },
                    {
                        type: 'linkNew',
                        component: 'FRRateBulkUpdateLink',
                        condition:
                            userHasFeature(
                                auth.user,
                                'RateMasterBulkUpdate',
                                '*'
                            ) && (
                                userHasFeature(
                                    auth.user,
                                    'FinancialReporting',
                                    'all'
                                ) ||
                                userHasFeature(
                                    auth.user,
                                    'FinancialReporting',
                                    'rate-master'
                                )),
                    },
                    {
                        type: 'linkNew',
                        component: 'FRFuelCategoryLink',
                        condition: userIsHDSStaff(auth.user.role) && userHasFeature(
                            auth.user,
                            'FinancialReporting',
                            'all'
                        ) ||
                        userHasFeature(
                            auth.user,
                            'FinancialReporting',
                            'fuel-category'
                        ),
                    },
                    {
                        type: "linkNew",
                        component: "FRRevenueReportLink",
                        condition:
                            userHasFeature(
                                auth.user,
                                "FinancialReporting",
                                "all"
                            ) ||
                            userHasFeature(
                                auth.user,
                                "FinancialReporting",
                                "revenue-report"
                            ),
                    },
                    {
                        type: "linkNew",
                        component: "FRRevenueByMetricReportLink",
                        condition:
                            userHasFeature(
                                auth.user,
                                "FinancialReporting",
                                "all"
                            ) ||
                            userHasFeature(
                                auth.user,
                                "FinancialReporting",
                                "revenue-report"
                            ),
                    },
                    {
                        type: "linkNew",
                        component: "FRRatesByClientLink",
                        condition:
                            userHasFeature(
                                auth.user,
                                "FinancialReporting",
                                "all"
                            ) ||
                            userHasFeature(
                                auth.user,
                                "FinancialReporting",
                                "rates-by-client"
                            ),
                    },
                    {
                        type: "linkNew",
                        component: "FRPayRatesLink",
                        condition:
                            userHasFeature(
                                auth.user,
                                "FinancialReporting",
                                "all"
                            ) ||
                            userHasFeature(
                                auth.user,
                                "FinancialReporting",
                                "pay-rates"
                            ),
                    },
                    {
                        type: "linkNew",
                        component: "FRRoutingGroupWeeklyOverhead",
                        condition:
                            userHasFeature(
                                auth.user,
                                "FinancialReporting",
                                "all"
                            ) ||
                            userHasFeature(
                                auth.user,
                                "FinancialReporting",
                                "weekly-overhead"
                            ),
                    },
                    {
                        type: "linkNew",
                        component: "FRDriverPayReportLink",
                        condition:
                            userHasFeature(
                                auth.user,
                                "FinancialReporting",
                                "all"
                            ) ||
                            userHasFeature(
                                auth.user,
                                "FinancialReporting",
                                "driver-pay"
                            ),
                    },
                    {
                        type: "linkNew",
                        component: "FRFuelSurcharge",
                        condition:
                            userHasFeature(
                                auth.user,
                                "FinancialReporting",
                                "all"
                            ) ||
                            userHasFeature(
                                auth.user,
                                "FinancialReporting",
                                "fuel-surcharges"
                            ) ||
                            userHasFeature(
                                auth.user,
                                "FinancialReporting",
                                "fuel-surcharges-edit"
                            ),
                    },
                    {
                        type: "link",
                        component: "FRClientInvoicingSummaryLink",
                        condition:
                            userHasFeature(
                                auth.user,
                                "FinancialReporting",
                                "all"
                            ) ||
                            userHasFeature(
                                auth.user,
                                "FinancialReporting",
                                "client-invoice"
                            ),
                    },
                    {
                        type: "link",
                        component: "FRClientInvoicingItemisedLink",
                        condition:
                            userHasFeature(
                                auth.user,
                                "FinancialReporting",
                                "all"
                            ) ||
                            userHasFeature(
                                auth.user,
                                "FinancialReporting",
                                "client-invoice"
                            ),
                    },
                ],
            },
            {
                condition: showNav,
                classNames: "deliveries receival pb-0",
                items: [
                    {
                        type: "heading",
                        text: "Drivers/Contractors",
                    },
                    {
                        type: "linkNew",
                        component: "BetaContractorsLink",
                        condition: config.betaURI && auth.user.role === "admin",
                    },
                    {
                        type: "linkNew",
                        component: "BetaContractorAssignLink",
                        condition: config.betaURI && auth.user.role === "admin",
                    },
                    {
                        type: "linkNew",
                        component: "BetaDriverAssignLink",
                        condition:
                            config.betaURI &&
                            (auth.user.role === "admin" ||
                                auth.user.role === "contractor"),
                    },
                    {
                        type: "linkNew",
                        component: "BetaVanAvailabilityLink",
                        condition:
                            config.betaURI &&
                            (auth.user.role === "admin" ||
                                auth.user.role === "contractor"),
                    },
                    {
                        type: "linkNew",
                        component: "BetaVanAvailabilityReportLink",
                        condition: config.betaURI && auth.user.role === "admin",
                    },
                    {
                        type: "link",
                        component: "VanAvailabilityExcessLink",
                        condition:
                            config.betaURI &&
                            auth.user.role === "admin" &&
                            userHasFeature(
                                auth.user,
                                "VanAvailabilityExcess",
                                "1"
                            ),
                    },
                    {
                        type: "link",
                        component: "ContractorPaySummaryLink",
                        condition:
                            config.betaURI &&
                            auth.user.role === "contractor" &&
                            userHasFeature(
                                auth.user,
                                "ContractorAccessPaySummary",
                                "1"
                            ),
                    },
                    {
                        type: "linkNew",
                        component: "ContractorPerformanceReportLink",
                        condition:
                            config.betaURI &&
                            (auth.user.role === "admin" ||
                                (userIsContractor(auth.user.role) &&
                                    userHasFeature(
                                        auth.user,
                                        "ContractorCanViewDriverPerformanceReport",
                                        "*"
                                    ))),
                    },
                    {
                        type: "linkNew",
                        component: "DriverPerformanceReportLink",
                        condition: config.betaURI && auth.user.role === "admin",
                    },
                    {
                        type: "linkNew",
                        component: "DriverAppVersionReportLink",
                        condition: config.betaURI && auth.user.role === "admin",
                    },
                    {
                        type: "linkNew",
                        component: "DriverErrorsReportLink",
                        condition: config.betaURI && auth.user.role === "admin",
                    },
                ],
            },
            {
                condition: showNav,
                classNames: "deliveries receival pb-0",
                items: [
                    {
                        type: "heading",
                        text: "Line Haul",
                    },
                    {
                        type: "linkNew",
                        component: "LineHaulJobsLink",
                        condition: config.betaURI && auth.user.role === "admin",
                    },
                    {
                        type: "linkNew",
                        component: "LineHaulJobAcceptanceLink",
                        condition: config.betaURI && auth.user.role === "admin",
                    },
                    {
                        type: "linkNew",
                        component: "LineHaulMovementsBuilderLink",
                        condition: config.betaURI && auth.user.role === "admin",
                    },
                    {
                        type: "linkNew",
                        component: "LineHaulMovementsAllocatorLink",
                        condition: config.betaURI && auth.user.role === "admin",
                    },
                    {
                        type: "linkNew",
                        component: "LineHaulMovementsLink",
                        condition: config.betaURI && auth.user.role === "admin",
                    },
                    {
                        type: "linkNew",
                        component: "LineHaulTruckLink",
                        condition: config.betaURI && auth.user.role === "admin",
                    },
                    {
                        type: "linkNew",
                        component: "LineHaulTrailerLink",
                        condition: config.betaURI && auth.user.role === "admin",
                    },
                    {
                        type: "linkNew",
                        component: "LineHaulLegLink",
                        condition: config.betaURI && auth.user.role === "admin",
                    },
                    {
                        type: "linkNew",
                        component: "LineHaulSubcontractorLink",
                        condition: config.betaURI && auth.user.role === "admin",
                    },
                    {
                        type: "linkNew",
                        component: "LineHaulDollyLink",
                        condition: config.betaURI && auth.user.role === "admin",
                    },
                    {
                        type: "linkNew",
                        component: "LineHaulScheduleLink",
                        condition: config.betaURI && auth.user.role === "admin",
                    },
                    {
                        type: 'linkNew',
                        component: 'LineHaulScheduleLink',
                        condition: config.betaURI && auth.user.role === 'admin',
                    },
                    {
                        type: "linkNew",
                        component: "LineHaulScoreboardLink",
                        condition: config.betaURI && auth.user.role === "admin",
                    },
                ],
            },
            {
                condition: showNav,
                classNames: "deliveries receival pb-0",
                items: [
                    {
                        type: "heading",
                        text: "Operations",
                    },
                    {
                        type: "linkNew",
                        component: "ScanningReportLink",
                        condition: userHasAccess(
                            auth.user.role,
                            "scanning-report"
                        ),
                    },
                    {
                        type: "linkNew",
                        component: "ReturnedtoDepotLink",
                        condition: userHasAccess(
                            auth.user.role,
                            "return-to-depot"
                        ),
                    },
                    {
                        type: "linkNew",
                        component: "RedeliveryBoxesLink",
                        condition:
                            config.betaURI && userIsHDSStaff(auth.user.role),
                    },
                    {
                        type: "linkNew",
                        component: "RoutingSummaryLink",
                        condition: userHasAccess(
                            auth.user.role,
                            "routing-summary"
                        ),
                    },
                    {
                        type: "linkNew",
                        component: "PreDeliveriesManageLink",
                        condition: auth.user.role === "admin",
                    },
                ],
            },
            {
                condition: showNav,
                classNames: "deliveries receival pb-0",
                items: [
                    {
                        type: "heading",
                        text: "Routing",
                    },
                    {
                        type: "linkNew",
                        component: "AdionaSyncLink",
                        condition: userHasAccess(auth.user.role, "sync"),
                    },
                    {
                        type: "linkNew",
                        component: "ManualRoutingLink",
                        condition: config.betaURI && auth.user.role === "admin",
                    },
                    {
                        type: "linkNew",
                        component: "ZonesLink",
                        condition:
                            userHasAccess(auth.user.role, "zones") ||
                            canViewZones,
                    },
                   
                ],
            },
            {
                condition: userHasAccess(auth.user.role, "packer"),
                classNames: "deliveries receival pb-0",
                items: [
                    {
                        type: "heading",
                        text: "Pick/Pack",
                    },
                    {
                        type: "linkNew",
                        component: "SummaryReportLink",
                        condition:
                            clientHasPackerSummaryReportAccess(currentClient),
                    },
                    {
                        type: "linkNew",
                        component: "ProductSummaryReportLink",
                        condition:
                            clientHasPackerProductSummaryReportAccess(
                                currentClient
                            ),
                    },
                    {
                        type: "linkNew",
                        component: "PickerPerformanceReportsLink",
                        condition:
                            clientHasPackerPickerPerformanceReportAccess(
                                currentClient
                            ),
                    },
                    {
                        type: "linkNew",
                        component: "FloorsLink",
                        condition: config.betaURI && auth.user.role === "admin",
                    },
                    {
                        type: "linkNew",
                        component: "ProductManagementLink",
                        condition:
                            userHasAccess(auth.user.role, "packer") &&
                            clientHasPackerToolsAccess(currentClient),
                    },
                    {
                        type: "linkNew",
                        component: "OrderBoxItemStatusesLink",
                        condition:
                            userHasAccess(auth.user.role, "packer") &&
                            clientHasPackerToolsAccess(currentClient),
                    },
                    {
                        type: "linkNew",
                        component: "WMSProductsLink",
                        condition:
                            config.betaURI &&
                            clientsHaveIntegration(clients.items, "dotwms"),
                    },
                    {
                        type: "linkNew",
                        component: "WMSLogsLink",
                        condition:
                            config.betaURI &&
                            auth.user.role === "admin" &&
                            clientsHaveIntegration(clients.items, "dotwms"),
                    },
                    {
                        type: "linkNew",
                        component: "BetaWMSOrderItemsLink",
                        condition:
                            config.betaURI &&
                            clientsHaveIntegration(clients.items, "dotwms"),
                    },
                    {
                        type: "linkNew",
                        component: "WarehouseInventoryLink",
                        condition:
                            config.betaURI &&
                            clientsHaveIntegration(clients.items, "dotwms"),
                    },
                    {
                        type: "link",
                        component: "InventoryStockAdjustmentsLink",
                        condition:
                            userHasFeature(
                                auth.user,
                                "InventoryAdjustment",
                                "1"
                            ) && auth.user.role === "admin",
                    },
                    {
                        type: "linkNew",
                        component: "WarehousesLink",
                        condition: config.betaURI && auth.user.role === "admin",
                    },
                    {
                        type: "linkNew",
                        component: "BetaPurchaseOrdersLink",
                        condition:
                            config.betaURI && auth.user.role !== "contractor",
                    },
                    {
                        type: "link",
                        component: "BetaAdvancedShippingNoticesLink",
                        condition:
                            config.betaURI && userIsHDSStaff(auth.user.role),
                    },
                    {
                        type: "linkNew",
                        component: "BetaWMSSyncedDeliveriesLink",
                        condition:
                            config.betaURI &&
                            clientsHaveIntegration(clients.items, "dotwms"),
                    },
                ],
            },
            {
                condition:
                    config.betaURI &&
                    (userIsHDSStaff(auth.user.role) ||
                        userHasFeature(auth.user, "RDSStores", "*")),
                classNames: "deliveries receival pb-0",
                items: [
                    {
                        type: "heading",
                        text: "RDS",
                    },
                    {
                        type: "linkNew",
                        component: "BetaRDSLink",
                        condition:
                            config.betaURI && userIsHDSStaff(auth.user.role),
                    },
                    {
                        type: "linkNew",
                        component: "BetaRDSStoresLink",
                        condition:
                            config.betaURI &&
                            (userIsHDSStaff(auth.user.role) ||
                                userHasFeature(auth.user, "RDSStores", "*")),
                    },
                    {
                        type: "link",
                        component: "RDSStoresUploadLink",
                        condition:
                            config.betaURI && userIsHDSStaff(auth.user.role),
                    },
                    {
                        type: "linkNew",
                        component: "BetaRDSServiceProvidersLink",
                        condition:
                            config.betaURI && userIsHDSStaff(auth.user.role),
                    },
                    {
                        type: "linkNew",
                        component: "RDSRunReport",
                        condition:
                            config.betaURI && userIsHDSStaff(auth.user.role),
                    },
                    {
                        type: "link",
                        component: "RetailSkillsLink",
                        condition:
                            config.betaURI && userIsHDSStaff(auth.user.role),
                    },
                    {
                        type: "linkNew",
                        component: "RetailStoresLink",
                        condition:
                            config.betaURI &&
                            (userIsHDSStaff(auth.user.role) ||
                                userIsAMemberOfClientId(auth.user, 888)),
                    },
                    {
                        type: "linkNew",
                        component: "RetailStoresImportLink",
                        condition:
                            config.betaURI && userIsHDSStaff(auth.user.role),
                    },
                    {
                        type: "linkNew",
                        component: "RetailStoresCreateExceptionLink",
                        condition:
                            config.betaURI &&
                            (userIsHDSStaff(auth.user.role) ||
                                userIsAMemberOfClientId(auth.user, 888)),
                    },
                    {
                        type: "linkNew",
                        component: "RetailStoresExceptionDailyReportLink",
                        condition:
                            config.betaURI &&
                            (userIsHDSStaff(auth.user.role) ||
                                userIsAMemberOfClientId(auth.user, 888)),
                    },
                    {
                        type: "link",
                        component: "RDSRoutingToolLink",
                        condition: userHasFeature(
                            auth.user,
                            "RDSRoutingTool",
                            "1"
                        ),
                    },
                    {
                        type: "linkNew",
                        component: "BetaAssetTrackerLink",
                        condition: userIsHDSStaff(auth.user.role),
                    },
                ],
            },
            {
                condition:
                    config.betaURI &&
                    (userIsHDSStaff(auth.user.role) ||
                        clientsHaveConfiguration(
                            clients.items,
                            "leastCostRoutingEnabled",
                            true
                        )),
                classNames: "deliveries receival pb-0",
                items: [
                    {
                        type: "heading",
                        text: "Courier Routing",
                    },
                    {
                        type: "linkNew",
                        component: "LCRCarrierLink",
                        condition:
                            config.betaURI && userIsHDSStaff(auth.user.role),
                    },
                    {
                        type: "linkNew",
                        component: "LCRCarrierZoneLink",
                        condition:
                            config.betaURI &&
                            (userIsHDSStaff(auth.user.role) ||
                                clientsHaveConfiguration(
                                    clients.items,
                                    "leastCostRoutingEnabled",
                                    true
                                )),
                    },
                    {
                        type: "linkNew",
                        component: "LCRClientRateLink",
                        condition:
                            config.betaURI &&
                            userIsHDSStaff(auth.user.role) &&
                            userHasFeature(auth.user, "LCRv2", "*"),
                    },
                    {
                        type: "linkNew",
                        component: "LCRCarrierFuelSurchargesLink",
                        condition:
                            config.betaURI && userIsHDSStaff(auth.user.role),
                    },
                    {
                        type: "linkNew",
                        component: "LCRCarrierLineHaulChargesLink",
                        condition:
                            config.betaURI && userIsHDSStaff(auth.user.role),
                    },
                    {
                        type: "linkNew",
                        component: "LCRCarrierFeesLink",
                        condition:
                            config.betaURI && userIsHDSStaff(auth.user.role),
                    },
                    {
                        type: "linkNew",
                        component: "LCRCourierReportDeliveryListLink",
                        condition:
                            config.betaURI && userIsHDSStaff(auth.user.role),
                    },
                    {
                        type: "linkNew",
                        component: "LCRCourierReportClientDeliveryListLink",
                        condition:
                            config.betaURI &&
                            (userIsHDSStaff(auth.user.role) ||
                                clientsHaveConfiguration(
                                    clients.items,
                                    "leastCostRoutingEnabled",
                                    true
                                )),
                    },
                    {
                        type: "linkNew",
                        component: "LCRAmbientBillingLink",
                        condition:
                            config.betaURI &&
                            userIsHDSStaff(auth.user.role) &&
                            userHasFeature(
                                auth.user,
                                "AmbientBillingReport",
                                "*"
                            ),
                    },
                    {
                        type: "linkNew",
                        component: "LCRCourierReportSummaryLink",
                        condition:
                            config.betaURI && userIsHDSStaff(auth.user.role),
                    },
                    {
                        type: "linkNew",
                        component: "LCRPickingLink",
                        condition:
                            config.betaURI && userIsHDSStaff(auth.user.role),
                    },
                    {
                        type: "linkNew",
                        component: "BilledWeightsUploadLink",
                        condition:
                            config.betaURI &&
                            userIsHDSStaff(auth.user.role) &&
                            userHasFeature(
                                auth.user,
                                "BilledWeightsUpload",
                                "*"
                            ),
                    },
                    {
                        type: "linkNew",
                        component: "LCRLocalityLookupLink",
                        condition:
                            config.betaURI &&
                            (userIsHDSStaff(auth.user.role) ||
                                clientsHaveConfiguration(
                                    clients.items,
                                    "leastCostRoutingEnabled",
                                    true
                                )) &&
                            userHasFeature(auth.user, "LCRv2", "*"),
                    },
                ],
            },
            {
                // condition: config.betaURI && userIsHDSStaff(auth.user.role),
                classNames: "deliveries receival pb-0",
                items: [
                    {
                        type: "heading",
                        text: "Tools",
                    },
                    {
                        type: "linkNew",
                        component: "UserManagementLink",
                        condition: userHasAccess(
                            auth.user.role,
                            "user-management"
                        ),
                    },
                    {
                        type: "linkNew",
                        component: "BetaImportLink",
                        condition:
                            config.betaURI &&
                            canUploadCsv &&
                            userHasAccess(auth.user.role, "upload-manifest"),
                    },
                    {
                        type: "linkNew",
                        component: "PrintOrdersLink",
                        condition: userHasAccess(
                            auth.user.role,
                            "client-tools"
                        ),
                    },
                    {
                        type: "linkNew",
                        component: "PickSheetOrderLink",
                        condition: userHasAccess(
                            auth.user.role,
                            "client-tools-picksheet-order"
                        ),
                    },
                    {
                        type: "linkNew",
                        component: "UndatedPrintLink",
                        condition: userHasAccess(auth.user.role, "runs"),
                    },
                    {
                        type: "linkNew",
                        component: "ForecastDeliveriesLink",
                        condition: userHasAccess(
                            auth.user.role,
                            "client-tools"
                        ),
                    },
                    {
                        type: "linkNew",
                        component: "ForecastRecipesLink",
                        condition: userHasAccess(
                            auth.user.role,
                            "client-tools"
                        ),
                    },
                    {
                        type: "linkNew",
                        component: "AuspostManifestsLink",
                        condition:
                            config.betaURI &&
                            clientsHaveIntegration(clients.items, "auspost"),
                    },
                    {
                        type: "linkNew",
                        component: "GeocodeReportLink",
                        condition: auth.user.role === "admin",
                    },
                ],
            },
            {
                condition: null,
                classNames: "deliveries receival pb-0",
                items: [
                    {
                        type: "linkNew",
                        component: "DocumentationLink",
                    },
                ],
            },
            {
                condition: config.betaURI && userIsHDSStaff(auth.user.role),
                classNames: "deliveries receival pb-0",
                items: [
                    {
                        type: "heading",
                        text: "Settings",
                    },
                    {
                        type: "linkNew",
                        component: "ClientsLink",
                        condition: config.betaURI && auth.user.role === "admin",
                    },
                    {
                        type: "linkNew",
                        component: "DepotsLink",
                        condition: config.betaURI && auth.user.role === "admin",
                    },
                    {
                        type: "linkNew",
                        component: "BoxSizesLink",
                        condition: config.betaURI && auth.user.role === "admin",
                    },
                ],
            },
            {
                condition: config.betaURI && userIsHDSStaff(auth.user.role),
                classNames: "deliveries receival pb-0",
                items: [
                    {
                        type: "heading",
                        text: "Dev",
                    },
                    {
                        type: "linkNew",
                        component: "WebhooksLink",
                        condition: userIsHDSStaff(auth.user.role),
                    },
                    {
                        type: "link",
                        component: "PackerLogsLink",
                        condition: config.betaURI && auth.user.role === "admin",
                    },
                    {
                        type: "linkNew",
                        component: "AppVersionsLink",
                        condition:
                            config.betaURI &&
                            config.betaURI &&
                            userIsDev(auth.user),
                    },
                    {
                        type: "linkNew",
                        component: "RecurringJobsLink",
                        condition:
                            config.betaURI &&
                            config.betaURI &&
                            userIsDev(auth.user),
                    },
                    {
                        type: "linkNew",
                        component: "TwilioTasksLink",
                        condition:
                            config.betaURI &&
                            config.betaURI &&
                            auth.user.role === "admin" &&
                            userHasFeature(auth.user, "TwilioTasksManage", "1"),
                    },
                    {
                        type: "linkNew",
                        component: "DotWMSLogsLink",
                        condition: userIsDev(auth.user),
                    },
                    {
                        type: "linkNew",
                        component: "RDSReconcile",
                        condition: userIsDev(auth.user),
                    },
                    {
                        type: "linkNew",
                        component: "SyncIssueLink",
                        condition:
                            config.betaURI && userIsHDSStaff(auth.user.role),
                    },
                    {
                        type: "linkNew",
                        component: "InventoryStockAdjustmentsV2Link",
                        condition:
                            userHasFeature(auth.user, "InventoryV2", "1") &&
                            auth.user.role === "admin",
                    },
                    {
                        type: "linkNew",
                        component: "InventoryV2Link",
                        condition:
                            userHasFeature(auth.user, "InventoryV2", "1") &&
                            auth.user.role === "admin",
                    },
                    {
                        type: "linkNew",
                        component: "LocalitiesLink",
                        condition: userHasAccess(auth.user.role, "localities") &&
                        userIsDev(auth.user),
                    },
                ],
            },
            {
                condition:
                    config.betaURI &&
                    (auth.user.role === "admin" ||
                        auth.user.role === "client-admin" ||
                        auth.user.role === "client-user"),
                classNames: "deliveries receival pb-0",
                items: [
                    {
                        type: "linkNew",
                        component: "MyAccountLink",
                        condition:
                            config.betaURI &&
                            (auth.user.role === "admin" ||
                                auth.user.role === "client-admin" ||
                                auth.user.role === "client-user"),
                    },
                ],
            },
        ];
    }, [auth.user, clients, notifications]);
    let showDeliveries = false;
    if (match && match.params && match.params.module === "cd") {
        showDeliveries =
            props.location.pathname.indexOf("rg") > -1 ||
            props.location.pathname.indexOf("dashboard") > -1;
    }

    let logoPath = currentClient?.logoURI || "";
    if (logoPath.substr(0, 1) === "/") {
        logoPath = `https://routing.homedelivery.com.au/${logoPath}`;
    } else if (logoPath.substr(0, 4) !== "http") {
        logoPath = `${config.s3ImageBase}/${logoPath}`;
    }

    if (currentClientId === -1) {
        logoPath = "/assets/hds-logo-new.svg";
    }

    const sitenavLinkBuilder = new SiteNavLinkBuilder(
        auth.user.role,
        currentClientId,
        formatDate(new Date(), "yyyy-MM-dd"),
        userHasMultipleClients(auth.user)
    );

    /** Commented Links */
    const SLALink = () => (
        <>
            {userHasAccess(auth.user.role, "sla") && (
                <NavLink to={`/cs/sla`}>
                    <button data-notifications="0" className="nav-button">
                        <i className="fas fa-cog"></i>
                        <span>Client SLA</span>
                    </button>
                </NavLink>
            )}
        </>
    );
    const LegacyUsersLink = () => (
        <>
            {userHasAccess(auth.user.role, "user-management") && (
                <NavLink
                    to={`/nv/users`}
                    isActive={(match, location) => {
                        if (!match) {
                            return false;
                        }

                        return location.pathname !== "/nv/users/self";
                    }}
                >
                    <button data-notifications="0" className="nav-button">
                        <i className="fas fa-cog"></i>
                        <span>Users</span>
                    </button>
                </NavLink>
            )}
        </>
    );
    const YoufoodzDailyReportLink = () => (
        <>
            {clientHasPackerYouFoodzDailyReportAccess(currentClient) && (
                <NavLink
                    to={`/packer/${
                        auth.user.role === "admin" ||
                        userHasMultipleClients(auth.user)
                            ? currentClientId + "/"
                            : ""
                    }reports/daily-summary`}
                >
                    <button
                        data-url="dashboard"
                        data-notifications="0"
                        className="nav-button"
                    >
                        <i className="fas fa-tachometer-alt" />
                        <span>Daily Summary Report</span>
                    </button>
                </NavLink>
            )}
        </>
    );
    const PickerTimeReportLink = () => (
        <>
            {clientHasPackerPickerPerformanceReportAccess(currentClient) && (
                <NavLink
                    to={`/packer/${
                        auth.user.role === "admin" ||
                        userHasMultipleClients(auth.user)
                            ? currentClientId + "/"
                            : ""
                    }reports/picker-time`}
                >
                    <button
                        data-url="dashboard"
                        data-notifications="0"
                        className="nav-button"
                    >
                        <i className="fas fa-tachometer-alt" />
                        <span>Picker Time Report</span>
                    </button>
                </NavLink>
            )}
        </>
    );
    const PickerWOWPerformanceLink = () => (
        <>
            {clientHasPackerPickerPerformanceReportAccess(currentClient) && (
                <NavLink
                    to={`/packer/${
                        auth.user.role === "admin" ||
                        userHasMultipleClients(auth.user)
                            ? currentClientId + "/"
                            : ""
                    }reports/picker-wow-performance`}
                >
                    <button
                        data-url="dashboard"
                        data-notifications="0"
                        className="nav-button"
                    >
                        <i className="fas fa-tachometer-alt" />
                        <span>Picker WOW Performance</span>
                    </button>
                </NavLink>
            )}
        </>
    );
    const BoxStatusLink = () => (
        <NavLink to={`/packer/reports/track-box-status-legacy`}>
            <button data-notifications="0" className="nav-button">
                <i className="fa fa-calendar-alt" />
                <span>Boxes status</span>
            </button>
        </NavLink>
    );
    const ItemStatusLink = () => (
        <NavLink to={`/packer/reports/track-item-status-legacy`}>
            <button data-notifications="0" className="nav-button">
                <i className="fa fa-calendar-alt" />
                <span>Items status</span>
            </button>
        </NavLink>
    );

    const checkCondition = (condition) => {
        if (typeof condition == "undefined" || condition === null) {
            return true;
        } else if (condition !== false) {
            return true;
        } else if (typeof condition === "function") {
            if (condition()) {
                return true;
            }
        }

        return false;
    };

    const toggleExpandedSection = (index) => {
        const newExpandedMenuSections = expandedMenu.indexes?.includes(index)
            ? expandedMenu.indexes?.filter((i) => i !== index)
            : [...(expandedMenu.indexes ?? []), index];
        setExpandedMenu({
            ...expandedMenu,
            indexes: newExpandedMenuSections,
            isDirty: true,
        });
    };

    const isExpanded = (sectionIndex) => {
        return expandedMenu.indexes?.includes(sectionIndex);
    };

    const MenuBuilder = ({
        navItemsAlt,
        collapsible,
        expandedMenuSections,
        handleToggleExpand,
        searchKey,
    }) => {
        return navItemsAlt.map((item, index) => {
            const sectionIsCollapsible =
                item.collapsible ?? (collapsible && item.items.length > 1);
            let sectionIsExpanded =
                !collapsible ||
                (!sectionIsCollapsible && item.items.length < 2) ||
                (sectionIsCollapsible && expandedMenuSections?.includes(index));

            let childLinks = item.items.filter(
                (itemSub) => itemSub.type !== "heading"
            );

            if (searchKey) {
                sectionIsExpanded = true;
                childLinks = childLinks.filter((itemSub) =>
                    sitenavLinkBuilder
                        .getLabel(itemSub.component, "new")
                        ?.toLowerCase()
                        .includes(searchKey?.trim().toLowerCase())
                );
            }

            const hasChildLink = childLinks
                .map((itemSub) => checkCondition(itemSub.condition))
                .find((itemSub) => itemSub === true);

            const render = (
                <div
                    className={`submenu ${item.classNames} ${
                        hasChildLink ? "has-child" : "no-child"
                    }`}
                    key={index}
                >
                    {item.items.map((itemSub, indexSub) => {
                        if (itemSub.type === "heading") {
                            if (sectionIsCollapsible) {
                                return (
                                    <a
                                        key={indexSub}
                                        className={`nav-button nav-button--collapse-toggle ${
                                            sectionIsExpanded
                                                ? "expanded"
                                                : "collapsed"
                                        }`}
                                    >
                                        <button
                                            onClick={() => {
                                                handleToggleExpand(index);
                                            }}
                                        >
                                            <h5>
                                                {itemSub.text}
                                                <div className="collapse-toggle">
                                                    {isExpanded(index) ? (
                                                        <i className="fas fa-caret-up"></i>
                                                    ) : (
                                                        <i className="fas fa-caret-down"></i>
                                                    )}
                                                </div>
                                            </h5>
                                        </button>
                                    </a>
                                );
                            } else {
                                return <h5 key={indexSub}>{itemSub.text}</h5>;
                            }
                        }
                        if (
                            (!sectionIsCollapsible || sectionIsExpanded) &&
                            checkCondition(itemSub.condition)
                        ) {
                            if (
                                sitenavLinkBuilder
                                    .getLabel(
                                        itemSub.component,
                                        itemSub.type === "linkNew"
                                            ? "new"
                                            : null
                                    )
                                    ?.toLowerCase()
                                    .includes(searchKey?.toLowerCase().trim())
                            ) {
                                return sitenavLinkBuilder.generateLink(
                                    itemSub.component,
                                    itemSub.notifications,
                                    itemSub.type === "linkNew",
                                    itemSub.type === "linkNew"
                                );
                            }
                        }
                    })}
                </div>
            );
            if (checkCondition(item.condition)) {
                return render;
            }
        });
    };

    const [searchKey, setSearchKey] = useState("");

    return (
        <aside css={containerCss} className={showing ? "" : "is-closed"}>
            <button
                type="button"
                className="sidemenu-trigger js-sidemenu-trigger"
                onClick={() => setShowing(!showing)}
            >
                <i className="fas fa-chevron-circle-left"></i>
            </button>
            <div className="wrap" ref={sideNavRef}>
                {showNav && (
                    <div className="logo">
                        <img
                            className="logo-img"
                            src={logoPath}
                            alt={currentClient?.displayName}
                        />
                        <div
                            css={{
                                display: "flex",
                                paddingTop: "9px",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "15px",
                                alignSelf: "stretch",
                                marginTop: "10px",
                            }}
                        >
                            <div
                                css={{
                                    color: colors.gray400,
                                    fontSize: "10px",
                                    fontWeight: 500,
                                }}
                            >
                                POWERED BY
                            </div>
                            <ArtechIcon />
                        </div>
                    </div>
                )}

                <div className="d-flex text-center justify-content-center align-items-center text-white"></div>

                <div className="menu-search my-4">
                    <TextInput
                        name="searchKey"
                        className="w-full"
                        placeholder="Search Menu"
                        value={searchKey}
                        onChange={(e) => {
                            setSearchKey(e.target.value);
                        }}
                    />
                    {searchKey && (
                        <button
                            className="text-field-clear"
                            onClick={() => {
                                setSearchKey("");
                            }}
                        >
                            <i className=" fa-regular fa-circle-xmark"></i>
                        </button>
                    )}
                </div>
                <MenuBuilder
                    searchKey={searchKey}
                    navItemsAlt={siteNavItems}
                    collapsible={showNewMenu}
                    expandedMenuSections={expandedMenu.indexes}
                    handleToggleExpand={toggleExpandedSection}
                />
                <div className="submenu stats">
                    <NavLink to="/auth/logout">
                        <button data-notifications="0" className="nav-button">
                            <i className="fa-solid fa-user" />
                            <span>Logout</span>
                        </button>
                    </NavLink>
                </div>
                {userIsDev(auth.user) && showNav && (
                    <div className="submenu stats">
                        <h5>Queue Info</h5>
                        <div className="stats">
                            <div className="text">
                                <span>Queued</span>
                                <span
                                    className="percents"
                                    data-percent={notifications?.queueLength}
                                ></span>
                            </div>
                        </div>
                        <div className="stats">
                            <div className="text">
                                <span>Servers</span>
                                <span
                                    className="percents"
                                    data-percent={notifications?.queueServers}
                                ></span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </aside>
    );
};

export default SiteNavigation;
