const stateGeoJSON = {
	1: {
		file: 'ACT.json',
		suburbKey: 'act_loca_2',
		postcodeKey: 'act_loca_4',
		unassignedColor: '#444444'
	},
	2: {
		file: 'NSW.json',
		suburbKey: 'nsw_loca_2',
		postcodeKey: 'nsw_loca_4',
		unassignedColor: '#444444'
	},
	3: {
		file: 'NT.json',
		suburbKey: 'nt_local_2',
		postcodeKey: 'nt_local_4',
		unassignedColor: '#444444'
	},
	4: {
		file: 'QLD.json',
		suburbKey: 'qld_loca_2',
		postcodeKey: 'qld_loca_4',
		unassignedColor: '#444444'
	},
	5: {
		file: 'SA_LOCALITY_F_S.geojson',
		suburbKey: 'SUBURB',
		postcodeKey: 'sa_local_4',
		unassignedColor: '#444444'
	},
	6: {
		file: 'TAS.json',
		suburbKey: 'tas_loca_2',
		postcodeKey: 'tas_loca_4',
		unassignedColor: '#444444'
	},
	7: {
		file: 'VIC.json',
		suburbKey: 'vic_loca_2',
		postcodeKey: 'vic_loca_4',
		unassignedColor: '#444444'
	},
	8: {
		file: 'WA.json',
		suburbKey: 'wa_local_2',
		postcodeKey: 'wa_local_4',
		unassignedColor: '#444444'
	}
}

const decodeEncodedPolyline = function (str, precision) {
	var index = 0,
		lat = 0,
		lng = 0,
		coordinates = [],
		shift = 0,
		result = 0,
		byte = null,
		latitude_change,
		longitude_change,
		factor = Math.pow(10, precision || 5);

	// Coordinates have variable length when encoded, so just keep
	// track of whether we've hit the end of the string. In each
	// loop iteration, a single coordinate is decoded.
	while (index < str.length) {

		// Reset shift, result, and byte
		byte = null;
		shift = 0;
		result = 0;

		do {
			byte = str.charCodeAt(index++) - 63;
			result |= (byte & 0x1f) << shift;
			shift += 5;
		} while (byte >= 0x20);

		latitude_change = ((result & 1) ? ~(result >> 1) : (result >> 1));

		shift = result = 0;

		do {
			byte = str.charCodeAt(index++) - 63;
			result |= (byte & 0x1f) << shift;
			shift += 5;
		} while (byte >= 0x20);

		longitude_change = ((result & 1) ? ~(result >> 1) : (result >> 1));

		lat += latitude_change;
		lng += longitude_change;

		coordinates.push(lat / factor);
		coordinates.push(lng / factor);
	}

	return coordinates;
};

const hexToRgbA = (hex, alpha) => {
	var c;
	if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
		c = hex.substring(1).split('');
		if (c.length == 3) {
			c = [c[0], c[0], c[1], c[1], c[2], c[2]];
		}
		c = '0x' + c.join('');
		return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
	}

	return 'rgba(0, 0, 0, 0.7)';
}

export { stateGeoJSON, decodeEncodedPolyline, hexToRgbA }