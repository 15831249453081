export const courierRouting = {
    LCRCarrierLink: {
        appDomain: "beta",
        uri: `/carrier-routing/carrier/all`,
        uriRegex: [/\/carrier-routing\/carrier\/(.*)/g],
        label: {
            new: "Carriers",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-repeat",
        },
        showBetaBadge: {
            old: false,
            new: false,
        },
    },
    LCRCarrierFuelSurchargesLink: {
        appDomain: "beta",
        uri: `/carrier-routing/fuel-surcharges`,
        uriRegex: [/\/carrier-routing\/fuel-surcharges/g],
        label: {
            new: "Carrier Fuel Surcharges",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-repeat",
        },
        showBetaBadge: {
            old: false,
            new: false,
        },
    },
    LCRCarrierLineHaulChargesLink: {
        appDomain: "beta",
        uri: `/carrier-routing/line-haul`,
        uriRegex: [/\/carrier-routing\/line-haul/g],
        label: {
            new: "Line Haul Fees",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-repeat",
        },
        showBetaBadge: {
            old: false,
            new: false,
        },
    },
    LCRCarrierFeesLink: {
        appDomain: "beta",
        uri: `/carrier-routing/fee`,
        uriRegex: [/\/carrier-routing\/feel/g],
        label: {
            new: "Last Mile/Tech Fees",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-repeat",
        },
        showBetaBadge: {
            old: false,
            new: false,
        },
    },
    LCRCarrierZoneLink: {
        appDomain: "beta",
        uri: `/carrier-routing/client-zone`,
        uriRegex: [/\/carrier-routing\/client-zone/g],
        label: {
            new: "Client Carriers",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-repeat",
        },
        showBetaBadge: {
            old: false,
            new: false,
        },
    },
    LCRCourierReportClientDeliveryListLink: {
        appDomain: "beta",
        uri: `/carrier-routing/reporting/client-delivery-list`,
        uriRegex: [/\/carrier-routing\/reporting\/client-delivery-list/g],
        label: {
            new: "Client Courier Report",
        },
        icon: {
            old: "fas fa-file-contract",
            new: "fas fa-file-contract",
        },
        showBetaBadge: {
            old: false,
            new: false,
        },
    },
    LCRCourierReportSummaryLink: {
        appDomain: "beta",
        uri: `/carrier-routing/reporting/summary`,
        uriRegex: [/\/carrier-routing\/reporting\/summary/g],
        label: {
            new: "Courier Summary",
        },
        icon: {
            old: "fas fa-file-contract",
            new: "fas fa-file-contract",
        },
        showBetaBadge: {
            old: false,
            new: false,
        },
    },
    LCRPickingLink: {
        appDomain: "beta",
        uri: `/carrier-routing/reporting/picking`,
        uriRegex: [/\/carrier-routing\/reporting\/picking/g],
        label: {
            new: "Ambient Picking Report",
        },
        icon: {
            old: "fas fa-file-contract",
            new: "fas fa-file-contract",
        },
        showBetaBadge: {
            old: false,
            new: false,
        },
    },
    LCRCourierReportDeliveryListLink: {
        appDomain: "beta",
        uri: `/carrier-routing/reporting/delivery-list`,
        uriRegex: [/\/carrier-routing\/reporting\/delivery-list/g],
        label: {
            new: "Courier Report",
        },
        icon: {
            old: "fas fa-file-contract",
            new: "fas fa-file-contract",
        },
        showBetaBadge: {
            old: false,
            new: false,
        },
    },
};
