export const routing = {
    AdionaSyncLink: {
        appDomain: "beta",
        uri: `/d/adiona`,
        label: {
            old: "Adiona Sync",
            new: "Optimize Routes",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-route",
        },
        tooltip: {
            old: "'Adiona Sync' are changing to 'Optimized Routes'",
            new: "'Optimized Routes' was titled 'Adiona Sync'",
        },
    },
    ManualRoutingLink: {
        appDomain: "beta",
        uri: `/d/manual-routing/all`,
        uriRegex: [/\/d\/manual-routing\/(.*)/g],
        showBetaBadge: {
            old: true,
            new: false,
        },
        label: {
            old: "Manual Routing",
            new: "Manual Routing",
        },
        icon: {
            old: "fas fa-route",
            new: "fas fa-route",
        },
    },
    ZonesLink: {
        appDomain: "beta",
        uri: `/z/zones`,
        label: {
            old: "Zones",
            new: "Zones",
        },
        icon: {
            old: "fas fa-layer-group",
        },
    },
};
