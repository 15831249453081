import { useSelector } from "react-redux";

/* @jsxImportSource @emotion/react */ import {
	css,
	jsx,
} from "@emotion/react"; /* eslint-disable-line */
import baseCss from "app/common/arctechv2/baseCss";
import { colors } from "app/common/arctechv2/theme";

import WarningIcon from "app/components/svgIcon/WarningIcon";

import formatCrossLink from "app/util/formatCrossLink";

const pageCss = css`
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;

	.content {
		display: flex;

		width: 400px;
		padding: 25px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 15px;
		flex-shrink: 0;

		border-radius: 20px;
		background: ${colors.white};

		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
			0px 1px 2px -1px rgba(0, 0, 0, 0.1);

		.title {
			color: var(--Grey-900, #111928);
			text-align: center;
			font-family: Inter;
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: 120%; /* 24px */
		}

		a {
			color: ${colors.gray900} !important;
			font-size: 15px;
			font-style: normal;
			font-weight: 500;
			line-height: 150%;
		}
	}
`;

const componentCss = css`
	display: flex;
	height: 47px;
	padding: 12px 20px 12px 19px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	align-self: stretch;

	border-radius: 8px;

	border: 1px solid ${colors.gray200};
	background: ${colors.gray100};

	transition: border-color 0.15s ease-in-out,
		background-color 0.15s ease-in-out, border 0.15s ease-in-out;

	&[disabled] {
		cursor: not-allowed;
	}

	&:not([disabled]):hover {
		background-color: ${colors.gray200};
		text-decoration: none;
		cursor: pointer;
	}

	&:focus {
		outline: none;
	}

	width: 100%;
`;

const ErrorPage = ({ title, errorMessage }) => {
	const auth = useSelector((state) => state.auth);

	return (
		<div css={[baseCss, pageCss]}>
			<div className="content">
				<WarningIcon color={colors.blue} />
				<div className="title">{title}</div>
				{errorMessage && (
					<div className="error-code">{errorMessage}</div>
				)}
				<a
					css={componentCss}
					href={formatCrossLink("/d/overview", auth.token)}
				>
					<i className="fa-regular fa-chevron-left" />
					Go back to Dashboard
				</a>
			</div>
		</div>
	);
};

export const PageRemoved = () => {
	const title = "Uh oh, this page no longer exists!";
	return <ErrorPage title={title} />;
};

export const PageNotFound = () => {
	const title = "Hmm, this page does not exist.";
	return <ErrorPage title={title} />;
};
