export const deliveryConfirmation = {
    WholesaleDeliveriesLink: {
        appDomain: 'beta',
        uri: `/d/wholesale-deliveries-confirm`,
        label: {
            old: 'Wholesale Deliveries',
            new: 'Wholesale Deliveries',
        },
        icon: {
            old: 'fas fa-truck-loading',
            new: 'fas fa-conveyor-belt-boxes',
        },
    },
    HomeDeliveriesConfirmLink: {
        appDomain: 'beta',
        uri: `/d/home-deliveries-confirm`,
        label: {
            old: 'Home Deliveries Confirmation',
            new: 'Home Deliveries Confirmation',
        },
        icon: {
            old: 'fas fa-truck-loading',
            new: 'fas fa-truck-loading',
        },
    },
    TemperatureCheckConfirmationLink: {
        appDomain: 'beta',
        uri: `/run/run-events/temp-check-confirmation`,
        uriRegex: [/\/run\/run-events\/temp-check-confirmation/g],
        label: {
            old: 'Temp Check Confirmation',
            new: 'Temp Check Confirmation',
        },
        icon: {
            old: 'fas fa-thermometer-quarter',
            new: 'fas fa-thermometer-quarter',
        },
    },
    DropInstructionsFollowedLink: {
        appDomain: 'beta',
        uri: `/run/drops-instructions-followed`,
        uriRegex: [/\/run\/drops-instructions-followed/g],
        label: {
            old: 'Drop Instructions Followed',
            new: 'Drop Instructions Followed',
        },
        icon: {
            old: 'fas fa-note',
            new: 'fas fa-note',
        },
    },
    ZoneLookupLink: {
        appDomain: 'beta',
        uri: `/z/lookup`,
        uriRegex: [/\/z\/lookup/g],
        label: {
            old: 'Zone Lookup',
            new: 'Zone Lookup',
        },
        icon: {
            old: 'fas fa-map',
            new: 'fas fa-map',
        },
    },
    CustomerChangeRequestsLinkBeta: {
        appDomain: 'beta',
        uri: `/d/customer-change/all`,
        uriRegex: [/\/d\/customer-change\/(.*)/g],
        label: {
            old: 'Customer Change Requests (Beta)',
            new: 'Customer Change Requests (Beta)',
        },
        icon: {
            old: 'fas fa-user-edit',
        },
    },
};
