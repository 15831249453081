export const rds = {
    BetaRDSLink: {
        appDomain: "beta",
        uri: `/rds/c/all`,
        uriRegex: [/\/rds\/c\/(.*)/g],
        label: {
            old: "RDS",
            new: "RDS Workflows",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-repeat",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    BetaRDSStoresLink: {
        appDomain: "beta",
        uri: `/rds/store/all`,
        uriRegex: [/\/rds\/store\/(.*)/g],
        label: {
            old: "RDS Stores",
            new: "Stores",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-shop",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    RDSStoresUploadLink: {
        appDomain: "beta",
        uri: `/rds/store/upload`,
        uriRegex: [/\/rds\/store\/upload/g],
        label: {
            old: "Stores Upload",
            new: "Stores Upload",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-shop",
        },
        showBetaBadge: {
            old: false,
            new: false,
        },
    },
    BetaRDSServiceProvidersLink: {
        appDomain: "beta",
        uri: `/rds/service-provider/all`,
        uriRegex: [/\/rds\/service-provider\/(.*)/g],
        label: {
            old: "RDS Service Providers",
            new: "Service Providers",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-shop",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    RDSRunReport: {
        appDomain: "beta",
        uri: `/rds/reports/run-report/all`,
        uriRegex: [/\/rds\/reports\/run-report\/(.*)/g],
        label: {
            old: "RDS Run Report",
            new: "RDS Run Report",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-shop",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    RetailSkillsLink: {
        appDomain: "beta",
        uri: `/d/retail-skills/all`,
        uriRegex: [/\/d\/retail-skills\/(.*)/g],
        label: {
            old: "Retail Skills",
            new: "Retail Skills",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-shop",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    RetailStoresLink: {
        appDomain: "beta",
        uri: `/d/retail-exceptions/manage/all`,
        uriRegex: [/\/d\/retail-exceptions\/manage\/(.*)/g],
        label: {
            old: "Retail Stores",
            new: "Retail Stores",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-shop",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    RetailStoresImportLink: {
        appDomain: "beta",
        uri: `/d/retail-exceptions/upload`,
        uriRegex: [/\/d\/retail-exceptions\/upload/g],
        label: {
            old: "Retail Stores Upload",
            new: "Retail Stores Upload",
        },
        icon: {
            old: "fas fa-upload",
            new: "fas fa-upload",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    RetailStoresCreateExceptionLink: {
        appDomain: "beta",
        uri: `/d/retail-exceptions`,
        uriRegex: [/\/d\/retail-exceptions/g],
        label: {
            old: "Retail Stores Exceptions",
            new: "Retail Stores Exceptions",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-shop",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    RetailStoresExceptionDailyReportLink: {
        appDomain: "beta",
        uri: `/d/retail-exceptions/reports/daily`,
        uriRegex: [/\/d\/retail-exceptions\/reports\/daily/g],
        label: {
            old: "Retail Stores Daily Exceptions",
            new: "Retail Stores Daily Exceptions",
        },
        icon: {
            old: "fas fa-chart-simple",
            new: "fas fa-chart-simple",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    BetaAssetTrackerLink: {
        appDomain: "beta",
        uri: `/mhe/equipment/map/all`,
        uriRegex: [/\/mhe\/equipment\/map\/(.*)/g],
        label: {
            new: "Asset Tracker",
        },
        icon: {
            old: "fa-sharp fa-solid fa-dolly",
            new: "fa-sharp fa-solid fa-dolly",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
};
