export const driverContractors = {
    BetaContractorsLink: {
        appDomain: "beta",
        uri: `/ctr/contractors/all`,
        uriRegex: [/\/ctr\/contractors\/(.*)/g],
        label: {
            old: "Contractors",
        },
        icon: {
            old: "fas fa-truck",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    BetaContractorAssignLink: {
        appDomain: "beta",
        uriRegex: [
            /\/run\/assign\/contractor\/1\/(.*)\/rg\/(.*)\/(.*)/g,
            /\/run\/assign\/contractorN\/1\/(.*)\/rg\/(.*)\/(.*)/g,
        ],
        label: {
            old: "Contractor Assign",
            new: "Assign Contractor",
        },
        icon: {
            old: "fas fa-truck",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    BetaDriverAssignLink: {
        appDomain: "beta",
        uriRegex: [/\/run\/assign\/driverN\/1\/(.*)\/rg\/(.*)\/(.*)/g],
        label: {
            old: "Driver Assign",
            new: "Assign Driver",
        },
        icon: {
            old: "fas fa-truck",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    BetaVanAvailabilityLink: {
        appDomain: "beta",
        uri: `/ctr/van-availability/grid`,
        uriRegex: [/\/ctr\/van-availability\/grid/g],
        label: {
            old: "Van Availability",
            new: "Van Availability",
        },
        icon: {
            old: "fas fa-truck",
            new: "fas fa-truck-clock",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    BetaVanAvailabilityReportLink: {
        appDomain: "beta",
        uri: `/ctr/van-availability/report`,
        uriRegex: [/\/ctr\/van-availability\/report/g],
        label: {
            old: "Van Availability Report",
            new: "Van Availability Report",
        },
        icon: {
            old: "fas fa-truck",
            new: "fas fa-truck-clock",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    DriverAppVersionReportLink: {
        appDomain: "beta",
        uri: `/ctr/reports/app-versions`,
        uriRegex: [/\/ctr\/reports\/app-versions/g],
        label: {
            old: "Driver App Versions Report",
            new: "Driver App Versions Report",
        },
        icon: {
            old: "fas fa-code-branch",
            new: "fas fa-code-branch",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    DriverErrorsReportLink: {
        appDomain: "beta",
        uri: `/run/errors/report`,
        uriRegex: [/\/run\/errors\/report/g],
        label: {
            old: "Driver Errors Report",
            new: "Driver Errors Report",
        },
        icon: {
            old: "fas fa-chart-line",
            new: "fas fa-chart-line",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
};
