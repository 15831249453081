export const settings = {
    ClientsLink: {
        appDomain: "beta",
        uri: `/c/clients/all`,
        uriRegex: [/\/c\/clients\/(.*)/g],
        label: {
            old: "Clients",
            new: "Clients",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-user-group",
        },
    },
    DepotsLink: {
        appDomain: "beta",
        uri: `/z/depots/all`,
        uriRegex: [/\/z\/depots\/(.*)/g],
        label: {
            old: "Depots",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-garage",
        },
    },
    BoxSizesLink: {
        appDomain: "beta",
        uri: `/d/box-sizes/all`,
        uriRegex: [/\/d\/box-sizes\/(.*)/g],
        label: {
            old: "Box Sizes",
        },
        icon: {
            old: "fas fa-ruler",
            new: "fas fa-ruler",
        },
    },
};
