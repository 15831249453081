import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import registerClientRedux from "app/resources/client";

const clientRedux = registerClientRedux("CLIENT_LIST", ["LIST"]);

const Header = () => {
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            clientRedux.actionCreators.list(auth, {
                includeAll: true,
                limit: 1000,
                sort: "name",
                inline: "configuration,integrationList",
            })
        );
    }, [auth.user.id]);

    return null;
};
Header.displayName = "Header";

export default Header;
