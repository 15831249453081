const Icon = ({ color }) => {
	return (
		<span>
			<svg
				width="24"
				height="21"
				viewBox="0 0 24 21"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M9.38549 1.64841C10.533 -0.391592 13.4685 -0.391592 14.6145 1.64841L22.9845 16.5284C24.1095 18.5294 22.665 20.9984 20.3715 20.9984H3.62999C1.33499 20.9984 -0.109514 18.5294 1.01549 16.5284L9.38549 1.64841ZM13.5 16.4999C13.5 16.8977 13.342 17.2793 13.0606 17.5606C12.7793 17.8419 12.3978 17.9999 12 17.9999C11.6022 17.9999 11.2206 17.8419 10.9393 17.5606C10.658 17.2793 10.5 16.8977 10.5 16.4999C10.5 16.1021 10.658 15.7206 10.9393 15.4393C11.2206 15.1579 11.6022 14.9999 12 14.9999C12.3978 14.9999 12.7793 15.1579 13.0606 15.4393C13.342 15.7206 13.5 16.1021 13.5 16.4999V16.4999ZM12 4.49991C11.6022 4.49991 11.2206 4.65794 10.9393 4.93925C10.658 5.22055 10.5 5.60208 10.5 5.99991V10.4999C10.5 10.8977 10.658 11.2793 10.9393 11.5606C11.2206 11.8419 11.6022 11.9999 12 11.9999C12.3978 11.9999 12.7793 11.8419 13.0606 11.5606C13.342 11.2793 13.5 10.8977 13.5 10.4999V5.99991C13.5 5.60208 13.342 5.22055 13.0606 4.93925C12.7793 4.65794 12.3978 4.49991 12 4.49991Z"
					fill={color}
				/>
			</svg>
		</span>
	);
};

export default Icon;
