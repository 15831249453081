import { admin } from "./links/admin";
import { courierRouting } from "./links/courierRouting";
import { deliveryConfirmation } from "./links/deliveryConfirmations";
import { dev } from "./links/dev";
import { deliveries } from "./links/deliveries";
import { driverContractors } from "./links/driversContractors";
import { lineHaul } from "./links/lineHaul";
import { operations } from "./links/operations";
import { otherRoutes } from "./links/otherRoutes";
import { pickPack } from "./links/pickPack";
import { rds } from "./links/rds";
import { reports } from "./links/reports";
import { routing } from "./links/routing";
import { settings } from "./links/settings";
import { tools } from "./links/tools";

export const navLinks = {
    links: {
        ...deliveries,
        ...admin,
        ...courierRouting,
        ...deliveryConfirmation,
        ...dev,
        ...driverContractors,
        ...lineHaul,
        ...operations,
        ...otherRoutes,
        ...pickPack,
        ...rds,
        ...reports,
        ...routing,
        ...settings,
        ...tools,
    },
    titleMap: [
        {
            path: /\/run\/r\/dropN\/(.*)/g,
            title: "Drop Detail",
        },
        {
            path: /\/run\/r\/delivery\/(.*)/g,
            title: "Delivery Detail",
        },
    ],
};
