export const admin = {
    SMSScheduleLink: {
        uri: `/sms`,
        label: {
            old: "SMS Schedule",
            new: "SMS Schedule",
        },
        icon: {
            old: "fas fa-sms",
            new: "fa fa-comment-sms",
        },
    },
    SMSBlockLink: {
        appDomain: "beta",
        uri: `/messaging/sms-block/all`,
        uriRegex: [/\/messaging\/sms-block\/(.*)/g],
        label: {
            old: "SMS Number Block",
            new: "SMS Number Block",
        },
        icon: {
            old: "fas fa-ban",
            new: "fa fa-ban",
        },
    },
    UnsubscribeEmailLink: {
        appDomain: "beta",
        uri: `/messaging/email-unsubscribes/all`,
        uriRegex: [/\/messaging\/email-unsubscribes\/(.*)/g],
        label: {
            old: "Email Unsubscribe",
            new: "Email Unsubscribe",
        },
        icon: {
            old: "fas fa-ban",
            new: "fa fa-ban",
        },
    },
};
