/* @jsxImportSource @emotion/react */  import { css, jsx } from '@emotion/react'; /* eslint-disable-line */
import { colors, altColors, alertColors } from './theme';
import { hexToRgbA } from 'app/util/map';

const cardCss = css`
	.card { 
		border: 1.5px solid #E1E2E4;
		border-radius: 20px;
		background-color: #fff;
		padding: 1rem;

		&:not(:first-of-type) {
			margin-top: 1rem;
		}

		.card {
			border-radius: 10px;
		}

		&.card--less-radius {
			border-radius: 10px;
		}

		&.card--noborder {
			border: none;
		}
	}
`

const itemsCss = css`
	.item { 
		line-height: 150%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		+ .item {
			margin-top: .5rem;
		}
	}
	.item-key {
		font-weight: 600;
		font-size: 13px;
	}
	.item-value { 
		font-weight: 400;
		font-size: 13px;
		margin-left: auto;
		text-align: right;
		display: flex;
		max-width: 60%;

		a {
			font-weight: 500;
		}
	}
	.item--block {
		flex-wrap: wrap;
		text-align: left;

		.item-key {
			margin-bottom: .25rem;
		}

		.item-key, 
		.item-value { 
			flex-basis: 100%;
			flex-wrap: wrap;
			text-align: left;
			max-width: unset;
		}
	}

	.item--driverdetail {
		justify-content: flex-start;

		.item-key {}
		.item-value {
			margin-left: 1rem;
			font-size: 16px;
			font-weight: 600;
			max-width: unset;
			text-align: right;

			a {
				font-weight: 600;
			}
		}
	}
`;

const datepickerCss = css`

	.react-datepicker, 
	.react-datepicker-wrapper {
		position: relative;
		background: #fff;
		border-radius: 5px;

		.calendar {
			background-color: transparent !important;
			border: 1px solid ${colors.gray300} !important;
			padding-top: 11px !important;

			> i {
				transform: translateY(1px);
			}
		}
		.far.fa-calendar-alt {
			background-color: transparent !important;
			color: ${colors.black} !important;
		}

		.react-datepicker__current-month {
			text-align: center;
		}

		.react-datepicker__navigation--previous {
			right: unset;
			left: 20px;
		}

		.react-datepicker__navigation--previous,
		.react-datepicker__navigation--next {
			top: 10px;
		}

		.react-datepicker__navigation--next {
			&:after {
				font-family: "Font Awesome 6 Pro";
				font-weight: 600;
				content: "\\f061";
				background: none;
			}
		}

		.react-datepicker__navigation--previous {
			&:after {
				font-family: "Font Awesome 6 Pro";
				font-weight: 600;
				content: "\\f060";
				background: none;
			}
		}

		.react-datepicker__day-name{
			font-size: 12px;
			text-transform: capitalize;
			color: #6B7280 !important;
		}

		.react-datepicker__day {
			&:hover {
				background-color: ${colors.blue}20;
				color: ${colors.blue};
			}
		}
		.react-datepicker__day--today {
			background-color: transparent;
			border: 1px solid ${colors.blue};
			color: ${colors.blue} !important;
		}

		.react-datepicker__day--selected {
			background-color: ${colors.blue} !important;
			color: #fff !important;
		}
		.react-datepicker__day--highlighted {
			border: none;

			&:after {
				content: " ";
				width: 4px;
				height: 4px;
				background-color: ${colors.red};
				border-radius: 50%;
				position: absolute;
				left: 0;
				right: 0;
				margin: 0 auto;
				bottom: 5px;
			}
		}

		.react-datepicker__day {
			font-weight: 600;
		}
	}

`;

const inputCss = css`
	// overrides current arctech styling
	.dropdown {
		border: 1px solid ${colors.gray300};
		border-radius: 8px !important;
		> span {
			width: 100%;
			display: flex;
			background-color: transparent !important;
			border: none;
			> i {
				margin-left: auto;
				display: flex;
				align-items: center;
				opacity: .5;
			}
		}
		.dropdown-menu-down, 
		.dropdown-menu-up {
			overflow: auto;
			max-height: 300px;
		}
	}

	textarea, 
	input[type="text"],
	input[type="email"],
	input[type="number"],
	input[type="password"],
	input[type="twofa"],
	input[type='input'] {
		background-color: transparent !important;
		border: 1px solid ${colors.gray300} !important;
		border-radius: 8px !important;

		&:focus, &:active {
			outline: 1px solid ${colors.blue} !important;
		}
		&[readonly] {
			background-color: ${colors.gray050} !important;
			&:focus, &:active {
				outline: none !important;
			}
		}
	}
	
	input[role="combobox"] {
		border: none !important;
		&:focus {
			outline: none !important;
		}
	}

	// select relation (override arctech styling)
	.select-relation {
		> div[class*="-container"] {
			> div[class*="-control"] {
				background-color: transparent !important;
				border: 1px solid ${colors.gray300} !important;
				border-radius: 8px !important;

				input {
					border: none !important;
					outline: none !important;
				}
			}
		}
	}

	// datepicker
	${datepickerCss}

	*::placeholder,
	.dropdown .btn.placeholder,
	.react-datepicker-wrapper .placeholder,
	.select-relation div[id*="-placeholder"] {
		color: ${colors.gray500};
		font-family: Inter;
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		opacity: 0.5;
	}
`;

const loadingCss = css`
	.loading {
		pointer-events: none;
	}

	.loading .loading-mask,
	.loading .item-key,
	.loading .item-value,
	.loading button,
		
	.loading .navi-item.navi-link,
	.loading .navi-item.navi-link .navi-text,
	.loading .navi-item.navi-link .navi-icon { 
		animation-duration: 2s;
		animation-fill-mode: forwards;
		animation-iteration-count: infinite;
		animation-name: placeHolderShimmer;
		animation-timing-function: linear;
		background-color: ${colors.gray300};
		background: linear-gradient(to right, ${colors.gray300} 1%, #bbbbbb 60%, ${colors.gray300} 90%);
		background-size: 800px 104px;
		position: relative;

		color: transparent !important;
		border-color: transparent !important;
		border-radius: 4px;

		&.loading-mask--icon-graphic:after {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;

			background-image: url(/assets/loading-placeholders/graphic.png);
			background-size: 30px;
			background-repeat: no-repeat;
			background-position: center;
		}
		&.loading-mask--icon-map:after {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;

			background-image: url(/assets/loading-placeholders/map.png);
			background-size: 30px;
			background-repeat: no-repeat;
			background-position: center;
		}
	}

	.loading .loading-mask {
		min-height: 50px;
	}
		
	.loading .loading-mask * {
		display: none;
	}

	.loading table td, .loading table th,
	.loading table td *, .loading table th * {
		text-shadow: 0px 0px 10px #bbbbbb;
		color: transparent !important;
	}

	.loading .item-key,
	.loading .item-value {
		border-radius: 4px;
		a {
			color: transparent;
		}
		button {
			display: none;
		}
	}
`;

const statusBadgeCss = css`
	.status-badge {
		padding: 2px 10px;
		border-radius: 6px;
		background: rgba(28, 100, 242, 0.1);
		color: ${colors.blue};
	}
	// ALT STYLING (for Loadoud Dashboard)
	.status-badge--alt {
		border-radius: 100px;
		font-weight: 600;
	}

	// COLOR VARIANTS
	.status-badge-pronounced {
		background: ${colors.blue};
		color: #ffffff;
	}
	.status-badge--success {
		background: #DEF7EC;
		color: #03543F;
	}
	.status-badge--success-pronounced {
		background: #14BA6D;
		color: #ffffff;
	}
	.status-badge--warning {
		background: #FFF4DE;
		color: #E59600;
	}
	.status-badge--warning-pronounced {
		background: #FFA803;
		color: #ffffff;
	}
	.status-badge--danger {
		background: #FDE8E8;
		color: #9B1C1C;
	}
	.status-badge--danger-pronounced {
		background: #E14640;
		color: #ffffff;
	}
	.status-badge--muted {
		background: ${hexToRgbA(colors.gray, 0.15)};
		color: ${altColors.gray};
	}
	.status-badge--optimized {
		background: #8950FC;
		color: #ffffff;
	}
`;

const slidingModalCss = css`
	&.modal-v2-sliding {
		margin: 20px;
		padding: 0 !important;
		width: 50vw;
		display: flex;
		flex-direction: column;

		.modal-header {
			padding: 25px;
			font-size: 20px;
			font-weight: 600;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1.5px solid ${colors.gray200};
			flex-basis: 80px;

			.modal-header-close {
				cursor: pointer;
				color: ${colors.gray200};
				font-size: 30px;

				&:hover {
					color: ${colors.gray500};
				}
			}
		}

		.modal-search {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			.input-searchKey-icon {
				position: absolute;
				left: 10px;
				color: ${colors.gray500};
				font-size: 14px;
			}
			.input-searchKey {
				input {
					padding-left: 30px;
					background-color: #fff !important;
				}
			}
		}

		.modal-body {
			padding: 25px;
			flex-basis: calc(100vh - 110px);
			overflow: auto;
			flex-shrink: 1;
			flex-grow: 1;
			display: flex;
			flex-direction: column;
		}
		.modal-footer {
			border-top: 1.5px solid ${colors.gray200};
			padding: 15px;
			flex-basis: 50px;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.subheader {
			font-size: 25px;
			font-weight: 500;
			margin-bottom: 5px;
		}
		.subheader-secondary {
			font-size: 18px;
			font-weight: 500;
		}
	}
`;

const handleCss = css`
	.handle {
		display: flex;
		cursor: grab;
		position: relative;
	}
	.handle-icon {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-basis: 40px;
		flex-shrink: 0;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
	}
	.handle-content {
		flex-grow: 1;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		border: 1px solid ${colors.gray300};
		border-left: none;
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
		padding: 10px;
		background-color: #fff;
	}

	.handle-text-main {
		font-size: 12px;
		font-weight: 600;
	}
	.handle-text-sub {
		font-size: 9px;
		font-weight: 590;
		letter-spacing: -0.43px;
		text-transform: uppercase;
		color: ${colors.gray400};
	}

	.handle-status {
		position: absolute;
		bottom: 2px;
		right: 10px;
		font-size: 9px;
		font-weight: 600;

		&.assigned {
			color: ${colors.green};
		}
	}

`;

export default css`
	font-family: 'Inter', sans-serif;

	.tc-white {color: #fff;}
	.tc-arctech-blue {color: ${colors.blue};}
	.tc-arctech-red {color: ${colors.red};}
	.tc-arctech-green {color: ${colors.green};}
	.tc-arctech-orange {color: ${colors.yellow};}
	.tc-arctech-black {color: ${colors.black};}
	.tc-arctech-charcoal {color: ${colors.charcoal};}
	.tc-arctech-gray {color: ${colors.gray};}
	.tc-arctech-gray500 {color: ${colors.gray500};}
	.tc-arctech-gray400 {color: ${colors.gray400};}
	.tc-arctech-gray300 {color: ${colors.gray300};}
	.tc-arctech-gray200 {color: ${colors.gray200};}
	.tc-arctech-gray100 {color: ${colors.gray100};}
	.tc-arctech-gray050 {color: ${colors.gray050};}
	.tc-arctech-purple {color: ${altColors.purple};}

	.bgc-arctech-blue {background-color: ${colors.blue} !important;}
	.bgc-arctech-lightblue {background-color: ${altColors.lightBlue} !important;}
	.bgc-arctech-red {background-color: ${colors.red} !important;}
	.bgc-arctech-green {background-color: ${colors.green} !important;}
	.bgc-arctech-orange {background-color: ${colors.yellow} !important;}
	.bgc-arctech-black {background-color: ${colors.black} !important;}
	.bgc-arctech-charcoal {background-color: ${colors.charcoal} !important;}
	.bgc-arctech-gray {background-color: ${colors.gray} !important;}
	.bgc-arctech-gray500 {background-color: ${colors.gray500} !important;}
	.bgc-arctech-gray050 {background-color: ${colors.gray050} !important;}

	.bgc-arctech-warning-low {background-color: ${alertColors.bgWarningLow} !important;}
	.bgc-arctech-alert-low {background-color: ${alertColors.bgDanger} !important;}
	.bgc-arctech-danger {background-color: ${alertColors.bgDanger} !important;}


	// basic element styling
	h3 { 
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
		margin-bottom: 1rem;
	}

	a { 
		color: ${colors.blue};
	}

	hr {
		border: none;
		height: 1px;
		background-color: #E1E2E4;
		margin-top: 15px;
		margin-bottom: 15px;
	}

	${inputCss}

	// base card styling
	${cardCss}

	// card items 
	${itemsCss}

	// status badges
	${statusBadgeCss}

	// sliding modal
	${slidingModalCss}

	// handle (draggable)
	${handleCss}


	&.page-wrapper {
		padding: 1rem;
	}

	.count-badge {
		display: inline-flex;
		height: 20px;
		padding: 0 0 0 8px;
		align-items: center;
		color: #fff;
		font-size: 12px;
		font-weight: 600;
		text-transform: uppercase;
		border-radius: 5px;
		background-color: ${altColors.lightBlue};

		.count {
			padding: 0 8px;
			height: 20px;
			background-color: ${colors.gray900};
			border-top-right-radius: 5px;
			border-bottom-right-radius: 5px;
			display: inline-flex;
			align-items: center;
			justify-self: flex-end;
			margin-left: 8px
		}

		+ .count-badge {
			margin-left: 10px;
		}

		&--red {
			background-color: ${altColors.red};
		}
	}

	// basic table styling 
	table {
		width: 100%;
	}
	tr {
		td {
			padding: 12px 5px;
			&:empty {
				padding: 0;
			}
		}
	}
	thead {
		text-transform: uppercase;
		color: #6B7280;
		tr {
			border-bottom: 1px solid #E5E7EB;
			th {
				padding: 16px 5px;
				font-weight: bold;
			}
		}
	}
	tbody {
		tr {
			border-bottom: 1px dashed #E5E7EB;
		}
		// &:hover {
		// 	background-color: rgba(0,0,0,0.1);
		// }
	}

	// loading styling
	${loadingCss}

	.w-full {width: 100%;}
	.w-half {width: 50%;}

	.min-h-50 {min-height: 50px;}
	.min-h-75 {min-height: 75px;}
	.min-h-100 {min-height: 100px;}

	*[disabled]{opacity: .5; cursor: not-allowed !important;}
	*[readOnly]{color: ${colors.gray500}; cursor: not-allowed !important; }

	/** HEREMAP INFOBUBBLE STYLING OVERRIDES */
	/** Style for the info bubble content */
	.H_ib_body {
		border-radius: 10px !important;
		border: none;
		font-family: 'Inter', sans-serif !important;
		box-shadow: none !important;
	}
		
	.H_ib_content {
		margin: 0 !important;
		.DropInfo {
			border: none !important;
		}
		.DropInfo-main-right {
			margin-top: 20px !important;
		}
	}

	/** Style for the info bubble tail */
	.H_ib.H_ib_top .H_ib_tail::after {
	}

	.H_ib_close {
		svg {
			path {
				fill: ${colors.black} !important;
			}
		}
	}
	.H_ib_tail {
		margin: -12px -10px;
		&:before {
			border-top-color: transparent !important;
		}
	}
`;

export {datepickerCss};