export const operations = {
    ScanningReportLink: {
        appDomain: "beta",
        uri: `/d/scan-report`,
        label: {
            old: "Scanning Report",
            new: "Scan Report",
        },
        icon: {
            old: "far fa-calendar-alt",
            new: "fa-regular fa-scanner-touchscreen",
        },
    },
    ReturnedtoDepotLink: {
        appDomain: "beta",
        uri: `/d/-1/return-to-depot`,
        label: {
            old: "Returned to Depot",
            new: "Return to Depot",
        },
        icon: {
            old: "fas fa-exchange-alt",
            new: "fa-regular fa-arrow-right-arrow-left",
        },
    },
    RedeliveryBoxesLink: {
        appDomain: "beta",
        uri: `/d/boxes-redelivery/all`,
        uriRegex: [/\/d\/boxes-redelivery\/(.*)/g],
        label: {
            old: "Redelivery Boxes",
            new: "Redelivery Boxes",
        },
        icon: {
            old: "fa-solid fa-retweet",
            new: "fa-solid fa-retweet",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    RoutingSummaryLink: {
        uri: `/d/routing-summary`,
        appDomain: "beta",
        label: {
            old: "Routing Summary",
            new: "Route Summary",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-route",
        },
    },
    PreDeliveriesManageLink: {
        appDomain: "beta",
        uri: `/d/pre-deliveries/promote`,
        label: {
            old: "Pre-Deliveries Manage",
            new: "Undated Deliveries Manage",
        },
        icon: {
            old: "fas fa-cog",
            new: "fa-regular fa-box",
        },
        tooltip: {
            old: "'Pre-Deliveries Manage' are changing to 'Undated Deliveries Manage'",
            new: "'Undated Deliveries Manage' was titled 'Pre-Deliveries Manage'",
        },
    },
};
