export const tools = {
    UserManagementLink: {
        appDomain: "beta",
        uri: `/u/users/all`,
        uriRegex: [/\/u\/users\/(.*)/g],
        label: {
            old: "User Management",
            new: "User Management",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-users",
        },
    },
    BetaImportLink: {
        appDomain: "beta",
        uri: `/d/delivery-upload`,
        uriRegex: [/\/d\/delivery-upload/g],
        label: {
            old: "Import Deliveries",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-cloud-upload-alt",
        },
    },
    PrintOrdersLink: {
        appDomain: "beta",
        uri: `/d/print-orders`,
        label: {
            old: "Print Orders",
            new: "Print Orders",
        },
        icon: {
            new: "fas fa-print",
        },
    },
    PickSheetOrderLink: {
        appDomain: "beta",
        label: {
            old: "Pick Sheet Order",
            new: "Pick Sheet Order",
        },
        icon: {
            old: null,
            new: "fa-regular fa-arrow-up-small-big",
        },
    },
    UndatedPrintLink: {
        uri: `/nv/deliveries/print`,
        label: {
            old: "Undated Print",
            new: "Print Undated orders",
        },
        icon: {
            old: "fas fa-layer-group",
            new: "fa-regular fa-print",
        },
    },
    ForecastDeliveriesLink: {
        appDomain: "beta",
        // uri: `/client-tools/${((user_role === "admin" || userHasMultipleClients) ? currentClientId + "/" : "")}orders/forecast`,
        uri: "/d/client-tools/orders/forecast",
        uriRegex: [/\/d\/client-tools\/orders\/forecast/g],
        label: {
            old: "Forecast Deliveries",
            new: "Forecast Deliveries",
        },
        icon: {
            old: null,
            new: "fas fa-chart-line-up",
        },
    },
    ForecastRecipesLink: {
        appDomain: "beta",
        label: {
            old: "Forecast Recipes",
            new: "Forecast Products",
        },
        icon: {
            old: null,
            new: "fas fa-chart-line-up",
        },
        tooltip: {
            old: "'Forecast Recipes' are changing to 'Forecast Products'",
            new: "'Forecast Products' was titled 'Forecast Recipes'",
        },
    },
    AuspostManifestsLink: {
        appDomain: "beta",
        uri: `/d/auspost-orders/all`,
        uriRegex: [/\/d\/auspost-orders\/(.*)/g],
        label: {
            old: "Auspost Manifests",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-clipboard-list",
        },
    },
    GeocodeReportLink: {
        uri: `/nv/zones/geocode-summary`,
        label: {
            old: "Geocode Report",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-clipboard-list",
        },
    },
};
