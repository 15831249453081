export const dev = {
    WebhooksLink: {
        appDomain: "beta",
        uri: `/d/webhooks/all`,
        label: {
            old: "Webhooks",
        },
        icon: {
            old: "fas fa-globe-asia",
            new: "fas fa-earth-asia",
        },
    },
    PackerLogsLink: {
        appDomain: "beta",
        uri: `/p/logs/all`,
        uriRegex: [/\/p\/logs\/(.*)/g],
        label: {
            old: "Packer Logs",
        },
        icon: {
            old: "fab fa-product-hunt",
        },
    },
    AppVersionsLink: {
        appDomain: "beta",
        uri: `/dev/app-versions/all`,
        uriRegex: [/\/dev\/app-versions\/(.*)/g],
        showBetaBadge: {
            old: true,
            new: false,
        },
        label: {
            old: "App Versions",
        },
        icon: {
            old: "fa-solid fa-dial",
        },
    },
    RecurringJobsLink: {
        appDomain: "beta",
        uri: `/dev/recurring-jobs/all`,
        uriRegex: [/\/dev\/recurring-jobs\/(.*)/g],
        showBetaBadge: {
            old: false,
            new: false,
        },
        label: {
            old: "Recurring Jobs",
        },
        icon: {
            old: "fa-solid fa-stopwatch",
        },
    },
    DotWMSLogsLink: {
        appDomain: "beta",
        uri: `/wms/logs/all`,
        uriRegex: [/\/wms\/logs\/(.*)/g],
        label: {
            old: "DotWMS Logs",
            new: "DotWMS Logs",
        },
        icon: {
            old: "fa-sharp fa-regular fa-square-list",
            new: "fa-sharp fa-regular fa-square-list",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    RDSReconcile: {
        appDomain: "beta",
        uri: `/rds/reconcile/all`,
        uriRegex: [/\/rds\/reconcile\/(.*)/g],
        label: {
            old: "RDS Reconcile",
            new: "RDS Reconcile",
        },
        icon: {
            old: "fas fa-warehouse-full",
            new: "fas fa-warehouse-full",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    SyncIssueLink: {
        appDomain: "beta",
        uri: `/d/sync-issues/all`,
        uriRegex: [/\/d\/sync-issues\/(.*)/g],
        label: {
            old: "Sync Issues",
            new: "Sync Issues",
        },
        icon: {
            old: "fas fa-triangle-exclamation",
            new: "fas fa-triangle-exclamation",
        },
        showBetaBadge: {
            old: false,
            new: false,
        },
    },
    MyAccountLink: {
        appDomain: "beta",
        uri: `/u/users/self`,
        uriRegex: [/\/u\/users\/self/g],
        label: {
            old: "My Account",
            new: "My Account",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-user-gear",
        },
    },
    LocalitiesLink: {
        appDomain: "beta",
        uri: `/dev/nv/locality`,
        label: {
            old: "Localities",
        },
        icon: {
            old: "fas fa-layer-group",
        },
    },
};
