export const reports = {
    DeliveriesSummaryLink: {
        appDomain: "beta",
        uri: `/d/rg-summary`,
        uriRegex: [/\/d\/rg-summary/g],
        label: {
            old: "Deliveries Summary",
            new: "Deliveries Summary",
        },
        icon: {
            old: "fas fa-tachometer-alt",
            new: "fa-regular fa-clipboard-list",
        },
    },
    AllClientForecastDeliveriesLink: {
        uri: `/cd/d/all-forecast-deliveries`,
        label: {
            old: "All Client Forecast Deliveries",
            new: "Forecasts",
        },
        icon: {
            old: "fas fa-utensils",
            new: "fa-regular fa-chart-line-up",
        },
    },
    ErrorRateLink: {
        appDomain: "beta",
        uri: `/d/error-rate`,
        label: {
            old: "Error Rate",
        },
        icon: {
            old: "fas fa-exclamation-triangle",
            new: "fas fa-exclamation-triangle",
        },
    },
    ErrorFailureReportLink: {
        label: {
            old: "Error/Failure Report",
            new: "Error / Fail Report",
        },
        icon: {
            old: "fas fa-exclamation-triangle",
            new: "fas fa-exclamation-triangle",
        },
    },
    OrderETALink: {
        label: {
            old: "Time Window Report",
            new: "ETA",
        },
        icon: {
            old: "fas fa-clock",
        },
    },
    NewOrderETALink: {
        uri: `/d/time-window-report`,
        uriRegex: [/\/d\/time-window-report/g],
        appDomain: "beta",
        label: {
            old: "Time Window Report",
            new: "Time Window Report",
        },
        icon: {
            old: "fas fa-clock",
        },
    },
    ZoneSuburbReportLink: {
        appDomain: "beta",
        label: {
            old: "Zone & Suburb Report",
            new: "Zone Summary",
        },
        icon: {
            old: "fas fa-map-marked",
            new: "fa fa-map-location-dot",
        },
    },
    CustomerFeedbackLink: {
        appDomain: "beta",
        uri: `/d/customer-feedback/all`,
        uriRegex: [/\/d\/customer-feedback\/(.*)/g],
        label: {
            old: "Customer Feedback",
        },
        icon: {
            old: "fas fa-bullhorn",
            new: "fas fa-bullhorn",
        },
    },
    CustomerFeedbackNLink: {
        appDomain: "beta",
        uri: `/d/customer-feedbackN/all`,
        uriRegex: [/\/d\/customer-feedbackN\/(.*)/g],
        label: {
            old: "Customer Feedback New",
        },
        icon: {
            old: "fas fa-bullhorn",
            new: "fas fa-bullhorn",
        },
    },
    AuspostDataReportLink: {
        appDomain: "beta",
        uri: `/d/auspost-data/all`,
        uriRegex: [/\/d\/auspost-data\/(.*)/g],
        label: {
            old: "Auspost Data Report",
            new: "Auspost Report",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-clipboard-list",
        },
    },
    BetaAPReportLink: {
        appDomain: "beta",
        uri: `/r/auspost`,
        uriRegex: [/\/r\/auspost/g],
        label: {
            old: "AP Report",
        },
        icon: {
            old: "fas fa-cog",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    DriverPerformanceReportLink: {
        appDomain: "beta",
        uri: `/ctr/reports/drivers-performanceN`,
        uriRegex: [/\/ctr\/reports\/drivers-performanceN/g],
        label: {
            old: "Driver Performance Report",
            new: "Driver Performance Report",
        },
        icon: {
            old: "fas fa-chart-line",
            new: "fas fa-chart-line",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
};
