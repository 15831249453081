export const otherRoutes = {
    ClientViewErrorsLink: {
        appDomain: "beta",
        uri: `/run/view-errors`,
        uriRegex: [/\/run\/view-errors/g],

        icon: {
            old: "fas fa-exclamation-circle",
            new: "fas fa-exclamation-circle",
        },
        showBetaBadge: {
            old: false,
            new: false,
        },
    },
    LegacyOrderSearchLink: {
        label: {
            old: "Order Search",
            new: "Order Search",
        },
        icon: {
            old: "fas fa-search",
        },
    },
    OrderSearchLink: {
        appDomain: "beta",
        uri: `/d/deliveriesN/all`,
        uriRegex: [/\/d\/deliveriesN\/(.*)/g],
        label: {
            old: "Order Search",
            new: "Order Search",
        },
        icon: {
            old: "fas fa-search",
        },
    },
    ClientSummaryReportLink: {
        label: {
            old: "Client Summary Report",
        },
        icon: {
            old: "fas fa-cog",
        },
    },
    CustomerNotificationSettingsLink: {
        label: {
            old: "Customer Notification Settings",
        },
        icon: {
            old: "fas fa-user-edit",
        },
    },
    RDSRoutingToolLink: {
        appDomain: "beta",

        uriRegex: [
            /\/d\/visualisation\/date\/(.*)\/rg\/(.*)\/rz\/(.*)\/z\/(.*)\/client\/(.*)\/service\/(.*)\/tw\/(.*)/g,
        ],
        showBetaBadge: {
            old: true,
            new: false,
        },
        label: {
            old: "RDS Routing Tool",
            new: "RDS Routing Tool",
        },
        icon: {
            old: "fas fa-route",
            new: "fas fa-route",
        },
    },
    VanAvailabilityExcessLink: {
        appDomain: "beta",
        uri: `/ctr/van-availability/excess`,
        uriRegex: [/\/ctr\/van-availability\/excess/g],
        label: {
            old: "Van Availability Excess",
            new: "Van Availability Excess",
        },
        icon: {
            old: "fas fa-truck",
            new: "fas fa-truck-clock",
        },
        showBetaBadge: {
            old: false,
            new: false,
        },
    },
    ContractorPerformanceReportLink: {
        appDomain: "beta",
        uri: `/ctr/reports/contractors-performanceN`,
        uriRegex: [/\/ctr\/reports\/contractors-performance/g],
        label: {
            old: "Contractor Performance Report",
            new: "Contractor Performance Report",
        },
        icon: {
            old: "fas fa-chart-line",
            new: "fas fa-chart-line",
        },
        showBetaBadge: {
            old: false,
            new: false,
        },
    },
    InventoryStockAdjustmentsLink: {
        appDomain: "beta",
        uri: `/wh/stock-adjustment/all`,
        uriRegex: [/\/wh\/stock-adjustment\/(.*)/g],
        label: {
            old: "Inventory Stock Adjustments WMS",
            new: "Inventory Stock Adjustments WMS",
        },
        icon: {
            old: "fas fa-warehouse-full",
            new: "fas fa-warehouse-full",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    InventoryStockAdjustmentsV2Link: {
        appDomain: "beta",
        uri: `/wh/stock-adjustment-v2/all`,
        uriRegex: [/\/wh\/stock-adjustment-v2\/(.*)/g],
        label: {
            old: "Inventory Stock Adjustments V2",
            new: "Inventory Stock Adjustments V2",
        },
        icon: {
            old: "fas fa-warehouse-full",
            new: "fas fa-warehouse-full",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    InventoryV2Link: {
        appDomain: "beta",
        uri: `/wh/inventory-v2/all`,
        uriRegex: [/\/wh\/inventory-v2\/(.*)/g],
        label: {
            old: "Inventory V2",
            new: "Inventory V2",
        },
        icon: {
            old: "fas fa-warehouse-full",
            new: "fas fa-warehouse-full",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    BetaStartrackBoxesLink: {
        appDomain: "beta",
        uri: `/d/startrack-boxes/all`,
        uriRegex: [/\/d\/startrack-boxes\/(.*)/g],
        label: {
            old: "Startrack Boxes",
            new: "Startrack Boxes",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-boxes",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    StartrackManifestsLink: {
        appDomain: "beta",
        uri: `/d/startrack-orders/all`,
        uriRegex: [/\/d\/startrack-orders\/(.*)/g],
        label: {
            old: "Startrack Manifests",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-clipboard-list",
        },
    },
    StartrackDataReportLink: {
        appDomain: "beta",
        uri: `/d/startrack-data/all`,
        uriRegex: [/\/d\/startrack-data\/(.*)/g],
        label: {
            old: "Startrack Data Report",
            new: "Startrack Report",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-clipboard-list",
        },
    },
    BilledWeightsUploadLink: {
        appDomain: "beta",
        uri: `/d/billed-weights/upload`,
        uriRegex: [/\/d\/build-weights\/upload/g],
        label: {
            old: "Upload Billed Weights",
            new: "Upload Billed Weights",
        },
        icon: {
            old: "fas fa-weight-scale",
            new: "fas fa-weight-scale",
        },
        showBetaBadge: {
            old: false,
            new: false,
        },
    },
    LCRLocalityLookupLink: {
        appDomain: "beta",
        uri: `/carrier-routing/locality-lookup`,
        uriRegex: [/\/carrier-routing\/locality-lookup\/*/g],
        label: {
            old: "Locality Lookup",
            new: "Locality Lookup",
        },
        icon: {
            old: "fas fa-weight-scale",
            new: "fas fa-weight-scale",
        },
        showBetaBadge: {
            old: false,
            new: false,
        },
    },
    AppLogLink: {
        appDomain: "beta",
        uri: `/app/logs/all`,
        uriRegex: [/\/app\/logs\/(.*)/g],
        label: {
            old: "App Logs",
            new: "App Logs",
        },
        icon: {
            old: "fas fa-pallet",
            new: "fas fa-pallet",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    DocumentationLink: {
        appDomain: "beta",
        uri: `/client-tools/docs-login`,
        label: {
            old: "Documentation",
            new: "Documentation",
        },
        icon: {
            old: null,
        },
    },
    TwilioTasksLink: {
        appDomain: "beta",
        uri: `/dev/twilio-tasks/all`,
        uriRegex: [/\/dev\/twilio-tasks\/(.*)/g],
        showBetaBadge: {
            old: false,
            new: false,
        },
        label: {
            old: "Twilio Tasks",
        },
        icon: {
            old: "fa-solid fa-list-check",
        },
    },
    PostSortationBoxValidationLink: {
        appDomain: "beta",
        uri: `/run/cleanup`,
        uriRegex: [/\/run\/cleanup/g],
        label: {
            new: "Post Sortation Box Validation",
        },
        icon: {
            new: "fas fa-box-check",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    RetailDeliveryBookingsLink: {
        appDomain: "beta",
        uri: `/d/retail-delivery-bookings/ewAiAHMAZQBsAGUAYwB0AGUAZABDAGwAaQBlAG4AdABzACIAOgBbADUAMgA2AF0ALAAiAGMAbABpAGUAbgB0AEkAZABzACIAOgAiADUAMgA2ACIAfQA`,
        uriRegex: [/\/d\/retail-delivery-bookings\/(.*)/g],
        label: {
            new: "Retail Delivery Bookings",
        },
        icon: {
            new: "fas fa-box-check",
        },
        showBetaBadge: {
            old: true,
            new: false,
        },
    },
    FRImportRatesLink: {
        appDomain: "beta",
        uri: `/b/client-rate-upload`,
        uriRegex: [/\/b\/client-rate-upload/g],
        label: {
            new: "Import Rates",
        },
        icon: {
            new: "fas fa-file-import",
        },
    },
    FRRateMasterLink: {
        appDomain: "beta",
        uri: `/b/client-rates/all`,
        uriRegex: [/\/b\/client-rates\/(.*)/g],
        label: {
            new: "Rate Master",
        },
        icon: {
            new: "fas fa-file-invoice-dollar",
        },
    },
    FRRateBulkUpdateLink: {
        appDomain: "beta",
        uri: `/b/client-rate-bulk-update`,
        uriRegex: [/\/b\/client-rate-bulk-update\/(.*)/g],
        label: {
            new: "Rate Bulk Update",
        },
        icon: {
            new: "fas fa-file-invoice-dollar",
        },
    },
    FRFuelCategoryLink: {
        appDomain: "beta",
        uri: `/b/fuel-category/all`,
        uriRegex: [/\/b\/fuel-category\/(.*)/g],
        label: {
            new: "Fuel Category",
        },
        icon: {
            new: "fas fa-gas-pump",
        },
    },
    FRRevenueReportLink: {
        appDomain: "beta",
        uri: `/b/revenue`,
        uriRegex: [/\/b\/revenue/g],
        label: {
            new: "Revenue Report",
        },
        icon: {
            new: "fas fa-sack-dollar",
        },
    },
    FRRevenueByMetricReportLink: {
        appDomain: "beta",
        uri: `/b/revenue-by-metric`,
        uriRegex: [/\/b\/revenue-by-metric/g],
        label: {
            new: "Revenue Metric Report",
        },
        icon: {
            new: "fas fa-sack-dollar",
        },
    },
    FRRatesByClientLink: {
        appDomain: "beta",
        uri: `/b/client-rates-by-zone`,
        uriRegex: [/\/b\/client-rates-by-zone/g],
        label: {
            new: "Rates by Client",
        },
        icon: {
            new: "fas fa-filter-circle-dollar",
        },
    },
    FRPayRatesLink: {
        uri: `/ct/pay-rates`,
        appDomain: "legacy",
        label: {
            new: "Pay Rates",
        },
        icon: {
            new: "fas fa-hand-holding-dollar",
        },
    },
    FRRoutingGroupWeeklyOverhead: {
        appDomain: "beta",
        uri: `/b/rg-weekly-cost`,
        uriRegex: [/\/b\/rg-weekly-cost/g],
        label: {
            new: "RG Weekly Overhead",
        },
        icon: {
            new: "fas fa-filter-circle-dollar",
        },
    },
    FRDriverPayReportLink: {
        appDomain: "beta",
        uri: `/b/driver-pay`,
        uriRegex: [/\/b\/driver-pay/g],
        label: {
            new: "Driver Pay Report",
        },
        icon: {
            new: "fas fa-sack-dollar",
        },
    },
    FRFuelSurcharge: {
        appDomain: "beta",
        uri: `/b/rg-fuel-surcharges`,
        uriRegex: [/\/b\/rg-fuel-surcharges/g],
        label: {
            new: "Fuel Surcharges",
        },
        icon: {
            new: "fas fa-sack-dollar",
        },
    },
    FRClientInvoicingSummaryLink: {
        appDomain: "beta",
        uri: `/b/client-invoicing`,
        uriRegex: [/\/b\/client-invoicing/g],
        label: {
            new: "Client Invoicing Summary",
        },
        icon: {
            new: "fas fa-sack-dollar",
        },
    },
    FRClientInvoicingItemisedLink: {
        appDomain: "beta",
        uri: `/b/client-invoicing-itemised/all`,
        uriRegex: [/\/b\/client-invoicing-itemised\/(.*)/g],
        label: {
            new: "Client Invoicing Itemised",
        },
        icon: {
            new: "fas fa-sack-dollar",
        },
    },
    LCRClientRateLink: {
        appDomain: "beta",
        uri: `/carrier-routing/client-rate/all`,
        uriRegex: [/\/carrier-routing\/client-rate/g],
        label: {
            new: "Ambient Client Rates",
        },
        icon: {
            old: "fas fa-cog",
            new: "fas fa-repeat",
        },
        showBetaBadge: {
            old: false,
            new: false,
        },
    },
    LCRAmbientBillingLink: {
        appDomain: "beta",
        uri: `/carrier-routing/reporting/ambient-billingn/all`,
        uriRegex: [/\/carrier-routing\/reporting\/ambient-billing/g],
        label: {
            new: "Ambient Billing",
        },
        icon: {
            old: "fas fa-file-contract",
            new: "fas fa-file-contract",
        },
        showBetaBadge: {
            old: false,
            new: false,
        },
    },
    ContractorPaySummaryLink: {
        appDomain: "beta",
        uri: `/ct/reports/pay-summary/all`,
        uriRegex: [/\/ct\/reports\/pay-summary\/(.*)/g],
        label: {
            old: "Pay Summary",
            new: "Pay Summary",
        },
        icon: {
            old: "fas fa-dollar",
            new: "fas fa-dollar",
        },
        showBetaBadge: {
            old: false,
            new: false,
        },
    },
};
