const colors = {
    blue900: "#233876",
    blue700: "#1A56DB",
    blue: "#1C64F2",
    blue400: "#76A9FA",
    blue300: "#A4CAFE",
    blue200: "#C3DDFD",
    blue100: "#E1EFFE",
    blue050: "#F4F7FE",
    green: "#14BA6D",
    yellow: "#FFA803",
    red700: "#C81E1E",
    orange: "#F07722",
    lightOrange: "#FDF4E6",
    red: "#E14640",
    red100: "#FDE8E8",
    black: "#111928",
    white: "#FFFFFF",
    charcoal: "#3D3D3C",
    lightYellow: "#FDF6B2",
    caramel: "#8E4B10",
    gray: "#CCCCCC",
    gray050: "#F9FAFB",
    gray100: "#F3F4F6",
    gray200: "#E5E7EB",
    gray300: "#D1D5DB",
    gray400: "#9CA3AF",
    gray500: "#6B7280",
    gray600: "#4B5563",
    gray700: "#374151",
    gray800: "#1F2A37",
    gray900: "#111928",
    placeholders: "#6B7280",
    lightPink: "#FDB4CA",
    darkNavy: "#181c32",
};

const altColors = {
    purple: "#673DE5",
    lightBlue: "#1FBCD1",
    red: "#F52166",
    successButton: "#057A55",
    successBg: "#DEF7EC",
    gray: "#34495E",
    yellow: "#FECB00",
};

const starRatingColors = {
    1: colors.red,
    2: colors.orange,
    3: colors.yellow,
    4: "#8AB138",
    5: colors.green,
};

const alertColors = {
    bgDanger: "#FDE8E8",
    textDanger: "#C81E1E",
    bgWarning: "#FDF6B2",
    bgWarningLow: "#fffdef",
    textWarning: "#8E4B10",
    bgInfo: "#E1EFFE",
    textInfo: "#1C64F2",
    bgSuccess: "#E8F8F2",
    textSuccess: altColors.successButton,
};

const palletColors = [
    `${altColors.purple}`,
    `#FECB00`,
    `${altColors.red}`,
    `${altColors.lightBlue}`,
    `#FE9700`,
    `#34495E`,
    `#DB40F4`,
    `#147E80`,
    `#C1392B`,
    `#FDB4CA`,
];
const opacityStrings = ["ff", "99", "66", "11"];

const getPalletColor = (index) => {
    return `${palletColors[index % palletColors.length]}${
        opacityStrings[Math.floor(index / palletColors.length)]
    }`;
};

const getNextPalletColor = (color) => {
    let _colorStr = color;
    let _opacityStr = null;
    let _opacityIdx = 0;
    let newIdx = 0;

    if (_colorStr.length !== 7) {
        _colorStr = color.substring(0, 7);
        _opacityStr = color.substring(7, 9);
        _opacityIdx = opacityStrings.indexOf(_opacityStr);
    }
    newIdx = `${_opacityIdx > 0 ? _opacityIdx : ""}${
        palletColors.indexOf(_colorStr) + 1
    }`;

    return `${getPalletColor(newIdx)}`;
};

export {
    colors,
    altColors,
    starRatingColors,
    alertColors,
    palletColors,
    getPalletColor,
    getNextPalletColor,
};
